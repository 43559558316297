.md-autocomplete-suggestions-container {
  background-color: white !important; }

md-autocomplete {
  margin-bottom: 0;
  width: 100%; }
  md-autocomplete md-autocomplete-wrap {
    box-shadow: none; }
    md-autocomplete md-autocomplete-wrap button {
      order: 2; }
    md-autocomplete md-autocomplete-wrap input {
      color: #000 !important; }

@keyframes circle1 {
  from {
    -webkit-transform: rotateZ(0deg); }
  to {
    -webkit-transform: rotateZ(360deg); } }

@keyframes ccircle1 {
  from {
    -webkit-transform: rotateZ(0deg); }
  to {
    -webkit-transform: rotateZ(-360deg); } }

@keyframes roll {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

html, body {
  height: 100%;
  position: relative; }

body.md-default-theme, body, html.md-default-theme, html {
  background: transparent;
  color: #333;
  font-weight: "";
  font-size: 15px;
  text-transform: "";
  line-height: 1.5; }

detect-browser {
  width: 100%; }

.button-danger {
  background-color: #f44336 !important;
  padding-left: 60px;
  padding-right: 60px; }

@media screen and (max-width: 800px) {
  .button-danger {
    padding-left: 20px;
    padding-right: 20px; } }

.button-forward {
  padding-left: 50px;
  padding-right: 50px; }

.button-info {
  background-color: #448aff !important;
  padding-left: 40px;
  padding-right: 40px; }

.button-waiting {
  background-color: #00C853 !important;
  color: #fff !important; }

.icon-red {
  color: #f44336 !important; }

.icon-green {
  color: #00c853 !important; }

.icon-blue {
  color: #1976d2 !important; }

md-toolbar {
  background: transparent; }

md-content.md-default-theme, md-content {
  background: transparent; }

.main-content md-content.md-default-theme, .main-content md-content {
  background: #fff; }

md-checkbox.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
  background-color: #2A6DD1; }

md-checkbox .md-container {
  width: 14px;
  height: 14px; }

md-checkbox .md-icon {
  width: 14px;
  height: 14px; }

md-table-pagination .label {
  color: #4b4b4d;
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase; }

.active {
  display: block; }

.material-icons {
  display: inline-block;
  vertical-align: middle; }
  .material-icons + span {
    display: inline-block;
    vertical-align: middle; }
  .material-icons.icon-paid {
    color: #00c853;
    font-weight: "";
    font-size: 18px;
    text-transform: ""; }
  .material-icons.icon-error {
    color: #f44336;
    font-weight: "";
    font-size: 18px;
    text-transform: ""; }
  .material-icons.icon-added {
    color: #1976D2;
    font-weight: "";
    font-size: 18px;
    text-transform: ""; }
  .material-icons.icon-picked {
    color: #FFB300;
    font-weight: "";
    font-size: 18px;
    text-transform: ""; }
  .material-icons.icon-unpaid {
    color: #f44336;
    font-weight: "";
    font-size: 18px;
    text-transform: ""; }
  .material-icons.icon-awaiting {
    color: #1976d2;
    font-weight: "";
    font-size: 18px;
    text-transform: ""; }
  .material-icons.icon-rejected {
    color: #F44336;
    font-weight: "";
    font-size: 18px;
    text-transform: ""; }
  .material-icons.icon-edition-required {
    color: #FFB300;
    font-weight: "";
    font-size: 20px;
    text-transform: ""; }
  .material-icons.icon-success {
    color: #14b933;
    font-weight: "";
    font-size: 18px;
    text-transform: ""; }
  .material-icons.icon-primary {
    color: #337ab7;
    font-weight: "";
    font-size: 18px;
    text-transform: ""; }
  .material-icons.icon-alphabet-alert-count {
    color: #f44336;
    font-weight: "";
    font-size: 18px;
    text-transform: ""; }
  .material-icons.icon-secondary {
    color: #717171;
    font-weight: "";
    font-size: 18px;
    text-transform: ""; }

table .material-icons {
  color: "";
  font-weight: "";
  font-size: 18px;
  text-transform: ""; }

table .material-icons-outlined {
  color: "";
  font-weight: "";
  font-size: 14px;
  text-transform: ""; }

.main-color {
  color: #448aff !important; }

md-input-container.md-default-theme .md-placeholder,
md-input-container .md-placeholder,
md-input-container.md-default-theme label, md-input-container label {
  color: rgba(75, 75, 77, 0.4);
  font-weight: 400;
  font-size: 16px;
  text-transform: none;
  line-height: 18px;
  margin-bottom: -4px;
  padding-top: 10px; }

md-input-container.md-default-theme md-select .md-select-value.md-select-placeholder,
md-input-container md-select .md-select-value.md-select-placeholder {
  color: rgba(75, 75, 77, 0.4);
  font-weight: 400;
  font-size: 16px;
  text-transform: none;
  line-height: 23px; }

md-input-container.md-default-theme .md-input,
md-input-container .md-input {
  font-family: "Roboto",sans-serif;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  text-transform: none;
  height: 45px;
  border-color: #9e9e9e; }

md-dialog.md-default-theme.md-content-overflow .md-actions, md-dialog.md-content-overflow .md-actions, md-dialog.md-default-theme.md-content-overflow md-dialog-actions, md-dialog.md-content-overflow md-dialog-actions, md-divider.md-default-theme, md-divider {
  border-color: #e1e1e1; }

.md-button.md-default-theme.md-raised, .md-button.md-raised {
  color: #757575;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  background: #e0e0e0;
  height: 40px;
  letter-spacing: normal;
  line-height: 40px; }

.hide {
  display: none; }

.md-input-messages-info {
  color: #757575;
  font-weight: 400;
  font-size: 12px;
  text-transform: ""; }

.md-input-messages-error, .md-input-messages-animation {
  color: #DD2C00;
  font-weight: 500;
  font-size: 12px;
  text-transform: ""; }

.errors {
  color: #DD2C00;
  font-weight: 500;
  font-size: 14px;
  text-transform: "";
  margin-bottom: 15px; }

md-dialog.change-password {
  width: 40%; }
  md-dialog.change-password md-input-container {
    margin-top: 20px; }
  md-dialog.change-password .md-input-messages-animation {
    margin: 0 8px 3px 8px; }

md-switch {
  white-space: normal; }

md-switch .md-label {
  margin-left: 15px; }

@media screen and (max-width: 800px) {
  md-switch .md-label {
    font-size: small;
    line-height: 0.8rem;
    margin-left: 0.2rem;
    width: 100%; } }

md-switch.ng-invalid-required .md-label:after {
  content: ' *';
  font-size: 13px;
  vertical-align: top;
  color: #dd2c00; }

md-checkbox {
  margin-bottom: 0px; }
  md-checkbox.md-checked .md-icon::after {
    margin-top: -3px;
    margin-left: -1px; }

md-content div[flex="50"] {
  padding: 5px 0; }

md-input-container > md-select {
  margin-top: 8px; }

.md-select-value > span:not(.md-select-icon) {
  padding-bottom: 11px; }

md-select.md-default-theme .md-select-value, md-select .md-select-value {
  border-bottom-color: #9e9e9e; }

md-autocomplete {
  margin-bottom: 0;
  width: 100%; }
  md-autocomplete md-autocomplete-wrap {
    box-shadow: none;
    background: #F9F9F9; }
    md-autocomplete md-autocomplete-wrap button {
      order: 2; }
    md-autocomplete md-autocomplete-wrap md-input-container {
      margin-right: 4px; }
      md-autocomplete md-autocomplete-wrap md-input-container .md-input {
        max-height: 42px; }
  md-autocomplete.white-background md-autocomplete-wrap {
    background: white; }
  md-autocomplete[md-floating-label] .md-show-clear-button input {
    padding-right: 0; }
  md-autocomplete .md-show-clear-button button md-icon {
    top: 20%; }

.landing-page-invoice {
  min-width: 450px !important; }

.self-enrollment-dialog {
  min-width: 300px !important; }

.md-dialog-content.rodo-md-dialog-content {
  padding: 5px 24px; }
  .md-dialog-content.rodo-md-dialog-content .dialog-info {
    margin-bottom: 40px;
    margin-top: 5px;
    display: block; }

.md-dialog-content md-input-container md-select .md-select-value.md-select-placeholder {
  line-height: 23px; }

.md-dialog-content .md-select-value > span:not(.md-select-icon) {
  padding-bottom: 10.5px; }

.md-dialog-content.profile-form md-input-container > md-select {
  margin: 20px 0 26px 0; }

.md-dialog-content md-autocomplete md-autocomplete-wrap {
  box-shadow: none;
  background: #fff; }
  .md-dialog-content md-autocomplete md-autocomplete-wrap button {
    order: 2; }
  .md-dialog-content md-autocomplete md-autocomplete-wrap input {
    background: #fff;
    padding: 0; }

.md-dialog-content .document-parameters {
  margin-top: 20px; }

.document-metric-users md-input-container md-autocomplete {
  margin-bottom: 0;
  width: 100%; }
  .document-metric-users md-input-container md-autocomplete md-autocomplete-wrap {
    box-shadow: none;
    background: #fff; }
    .document-metric-users md-input-container md-autocomplete md-autocomplete-wrap button {
      order: 2; }
    .document-metric-users md-input-container md-autocomplete md-autocomplete-wrap input {
      background: #fff; }

md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
  bottom: -2px;
  right: 0;
  left: 0;
  width: auto; }

md-input-container:not(.md-input-has-value) input:not(:focus), md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-ampm-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-day-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-hour-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-millisecond-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-minute-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-month-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-second-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-week-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-year-field, md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-text {
  color: #000; }

/* ==========================================================================
Social button
========================================================================== */
.jh-btn-social {
  margin-bottom: 4px;
  text-transform: none;
  background-color: #f2f3f5;
  border-color: rgba(0, 0, 0, 0.2);
  color: #0d1216;
  font-weight: 400;
  font-size: 13px;
  text-transform: ""; }

.jh-btn-social:hover, .jh-btn-social:focus, .jh-btn-social:active, .jh-btn-google.active {
  margin-bottom: 4px;
  text-transform: none;
  background-color: #e1e4e7;
  border-color: rgba(0, 0, 0, 0.2);
  color: #0d1216;
  font-weight: 400;
  font-size: 13px;
  text-transform: ""; }

.social-icon {
  margin-right: 5px; }

.tooltip {
  position: relative; }

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 320px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1000;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s; }

md-dialog .tooltip .tooltiptext {
  bottom: 105%;
  margin-left: -110px;
  visibility: hidden;
  width: 100%; }

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; }

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1; }

.md-chips .md-chip-input-container {
  width: 100%; }
  .md-chips .md-chip-input-container .md-input {
    width: 100%; }

.tags-chips md-chip, .tags-chips md-chip.md-focused {
  background: #e0e0e0 !important;
  color: #424242 !important; }
  .tags-chips md-chip md-icon, .tags-chips md-chip.md-focused md-icon {
    color: #616161 !important; }

md-switch.document-skip-address {
  margin: 16px 0px 0px 0px; }

.number-of-pages {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.landing-input-container {
  margin-top: 5px; }

.landing-button {
  margin: 2px 2px; }

.landing-checkbox {
  font-size: 14px;
  font-weight: 300; }

md-checkbox.accept-data-processing .md-container {
  top: 12px; }

md-dialog .md-dialog-content-section-similar-documents {
  margin-left: 3.34rem;
  margin-right: 0.67rem; }

md-dialog .md-dialog-action-section-similar-documents {
  margin-left: 3.34rem;
  margin-right: 0.67rem;
  padding: 1.6rem;
  margin-bottom: 0rem; }

md-dialog .md-dialog-content-section-similar-documents-proceed {
  left: 4.94rem;
  margin-bottom: 2rem; }

.document-metric-author md-input-container {
  height: 46px; }

.session-tooltip {
  height: auto;
  text-align: center;
  padding: 10px 20px;
  font-size: 13px; }

.session-label {
  margin-top: 3px; }

.cooauthors-add {
  margin-bottom: 20px !important;
  margin-top: 15px !important; }

.out-circle {
  margin-top: -7px;
  width: 12px;
  height: 12px;
  position: absolute;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px; }
  .out-circle.assignment-checking {
    margin-top: -13px;
    margin-left: 25px; }

.out-circle .rotate.anim1 {
  -webkit-animation: circle1 2s infinite linear; }

.out-circle .rotate {
  width: 100%;
  height: 100%;
  position: absolute; }

.out-circle .rotate .counterrotate {
  width: 15px;
  height: 20px;
  -webkit-animation: ccircle1 2s infinite linear; }

.out-circle .rotate .counterrotate .inner.material-icons {
  color: #1976d2;
  font-weight: "";
  font-size: 18px;
  text-transform: none; }

.out-circle .rotate .counterrotate .inner {
  width: 10px;
  height: 10px;
  text-align: center;
  vertical-align: middle;
  display: table-cell; }

.roll {
  display: inline-block;
  animation: roll 3s infinite;
  transform: rotate(30deg); }

.site-container {
  height: 100vh;
  background-color: #e1e3e6;
  display: block; }

.loader {
  top: 30%;
  width: 99%;
  position: absolute;
  z-index: 2; }
  .loader.relative {
    position: relative; }

md-input-container > md-select.show-limit {
  margin-top: 0; }

.table-container md-content {
  margin: 0;
  min-height: 500px; }
  .table-container md-content.auto-height {
    min-height: auto; }
  .table-container md-content md-card md-table-container {
    display: block;
    max-width: 100%;
    overflow-x: auto; }

.table-container .box-limit .md-select.md-default-theme .md-select-value, .table-container .box-limit md-select .md-select-value {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 10px;
  min-width: 55px;
  padding: 0;
  text-align: center;
  width: 55px; }
  .table-container .box-limit .md-select.md-default-theme .md-select-value .md-text, .table-container .box-limit md-select .md-select-value .md-text {
    margin: 0; }

.table-container .search-buttons {
  color: #000;
  font-weight: 500;
  font-size: 12px;
  text-transform: "uppercase"; }

.table-container table md-menu-bar {
  padding: 0;
  display: flex;
  justify-content: center; }

.table-container table button {
  padding: 0;
  height: 20px;
  line-height: 20px; }
  .table-container table button.md-raised {
    padding: 0;
    height: 20px;
    line-height: 20px; }
  .table-container table button.md-raised {
    padding: 0;
    height: 20px;
    line-height: 20px; }

.table-container table .md-button.md-default-theme.md-raised, .table-container table .md-button.md-raised {
  padding: 0;
  height: 30px;
  min-height: 30px;
  line-height: 30px; }

.table-container table.md-table th.md-column {
  padding: 0 8px; }
  .table-container table.md-table th.md-column.documents-wp {
    text-align: left; }
  .table-container table.md-table th.md-column.md-numeric {
    text-align: left; }
  .table-container table.md-table th.md-column.table-actions {
    text-align: center;
    padding: 0 10px 0 0; }

.table-container table.md-table .table-details {
  padding-top: 5px; }
  .table-container table.md-table .table-details td.md-cell {
    padding: 15px 8px 0 !important;
    vertical-align: top; }
    .table-container table.md-table .table-details td.md-cell md-menu-bar md-menu {
      margin-top: -10px !important; }
    .table-container table.md-table .table-details td.md-cell button {
      margin-top: -5px; }
  .table-container table.md-table .table-details .details-info {
    left: 7px;
    width: 99%;
    padding-left: 80px;
    box-sizing: border-box;
    border-top: 1px solid #d0d0d0; }

@-moz-document url-prefix() {
  .table-container table.md-table .table-details .details-info {
    left: 0;
    width: 100%; } }
    .table-container table.md-table .table-details .details-info .detail-label {
      margin: 5px 0;
      color: #757575;
      font-weight: 400;
      font-size: 12px;
      text-transform: "none";
      line-height: 1; }
    .table-container table.md-table .table-details .details-info .detail-info {
      margin: 5px 0;
      color: #000;
      font-weight: 400;
      font-size: 12px;
      text-transform: "none";
      line-height: 1; }
    .table-container table.md-table .table-details .details-info .show-docs {
      margin-top: 20px;
      color: #1a4585;
      font-weight: 400;
      font-size: 12px;
      text-transform: "uppercase"; }

.table-container table.md-table td.md-cell {
  padding: 0 8px;
  color: #212121;
  font-weight: 400;
  font-size: 12px;
  text-transform: "none"; }
  .table-container table.md-table td.md-cell.md-checkbox-cell md-checkbox.md-checked .md-icon::after {
    margin-top: -3px;
    margin-left: -1px; }
  .table-container table.md-table td.md-cell.md-numeric {
    text-align: center; }
  .table-container table.md-table td.md-cell.documents-wp {
    text-align: left; }
  .table-container table.md-table td.md-cell.wp-width {
    min-width: 50px; }
  .table-container table.md-table td.md-cell.documents-status {
    padding-left: 8px !important; }

.table-container table.md-table td.table-actions {
  max-width: 36px; }

.table-container table.md-table tfoot.md-foot > tr.md-row {
  height: 15px; }

.table-container table.md-table .docu-title-ellipsis {
  max-width: 200px;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2), table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
  padding: 0 0.5rem 0 0; }

table.md-table.md-row-select td.md-cell:first-child, table.md-table.md-row-select th.md-column:first-child {
  padding: 0 0 0 0.5rem; }

md-input-container.md-radio-group-block {
  min-height: 50px; }

.span-firstname {
  margin-left: 5px; }

.md-datepicker-input {
  min-width: 220px; }

md-bottom-sheet {
  background-color: #1a4585;
  padding-left: 5%;
  border: 0;
  color: #fff;
  position: fixed; }
  md-bottom-sheet i {
    color: #fff; }
  md-bottom-sheet span {
    cursor: pointer; }

.table-document-fixed-container {
  max-height: 420px; }

.table-fixed-container {
  max-height: 260px; }

table.md-table.table-fixed {
  overflow: auto; }
  table.md-table.table-fixed thead {
    position: sticky;
    position: -webkit-sticky;
    top: -10px;
    z-index: 9; }
    table.md-table.table-fixed thead th {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 9;
      background: #fff; }

.tooltip .paper-validity-tooltip {
  width: 29rem;
  margin-left: -14.53rem; }

#rodo .rodo-dontaccept-label {
  padding-top: 20px;
  color: #777; }

#rodo .rodo-accept-label {
  padding-top: 20px;
  color: #1a4585;
  font-weight: "";
  font-size: 16px;
  text-transform: "bold"; }

.tooltip-with-long-text {
  font-size: 1rem;
  max-width: 60rem;
  height: auto;
  text-align: center;
  white-space: normal; }

.radio-info {
  font-size: 11px;
  font-weight: lighter; }

.word-break-all {
  word-break: break-all; }

md-input-container .md-errors-spacer {
  min-height: 0 !important; }

.vh-80 {
  height: 80vh !important; }

.disabled-row {
  background-color: #d8d8d8; }

.warning-color {
  color: #f44336;
  font-weight: bold; }

.bold {
  font-weight: bold; }

.white-div {
  min-height: 20px !important;
  color: #ffffff; }

.nowdisabled {
  color: rgba(0, 0, 0, 0.38); }

.unlogged md-content {
  padding-top: 20px;
  justify-content: center;
  overflow: visible; }
  .unlogged md-content .form {
    max-width: 420px; }
    .unlogged md-content .form md-card {
      display: block; }
      .unlogged md-content .form md-card md-card-title {
        padding: 0 16px 0; }
    .unlogged md-content .form .choose-lang {
      color: rgba(207, 216, 220, 0.9);
      font-weight: "";
      font-size: 11px;
      text-transform: "";
      padding: 0; }
    .unlogged md-content .form .login-logo {
      background-image: url("../images/login-logo.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 150px;
      height: 51px;
      margin: 10px auto 0;
      cursor: pointer; }
    .unlogged md-content .form .login-with {
      color: #757575;
      font-weight: 500;
      font-size: 13px;
      text-transform: none;
      opacity: 0.7;
      text-align: center;
      padding: 8px 16px; }
    .unlogged md-content .form .login-with-btn {
      padding-top: 0;
      padding-bottom: 8px; }
    .unlogged md-content .form .login-header {
      margin-top: 20px;
      color: #1a4585;
      font-weight: 400;
      font-size: 20px;
      text-transform: none; }
    .unlogged md-content .form .login-info {
      text-align: center; }
    .unlogged md-content .form .md-subheader.md-default-theme, .unlogged md-content .form .md-subheader {
      color: red;
      font-weight: 200;
      font-size: 16px;
      text-transform: none;
      background: transparent; }
    .unlogged md-content .form .md-block {
      margin-top: 20px; }
      .unlogged md-content .form .md-block.username {
        margin-top: 40px; }
    .unlogged md-content .form .ng-active {
      color: none;
      font-weight: 300;
      font-size: 12px;
      text-transform: none;
      opacity: 0.75; }
    .unlogged md-content .form .register-regulations-acceptance-info {
      margin: auto;
      font-size: 10.5px;
      font-weight: normal;
      text-align: left;
      line-height: 120%;
      padding-bottom: 1rem; }
      .unlogged md-content .form .register-regulations-acceptance-info a {
        text-decoration: none;
        color: black;
        font-weight: bold; }
      .unlogged md-content .form .register-regulations-acceptance-info.info-on-register-screen {
        padding-bottom: 0; }
    .unlogged md-content .form .register-forgot-password {
      color: #757575;
      font-weight: 500;
      font-size: 13px;
      text-transform: none; }
    .unlogged md-content .form .register-label {
      color: #757575;
      font-weight: 500;
      font-size: 13px;
      text-transform: none;
      line-height: 30px;
      opacity: 0.7; }

.unlogged md-content.md-default-theme, .unlogged md-content {
  background: transparent; }

@media screen and (max-width: 1440px) {
  md-content div[flex="50"].login-logo {
    padding: 25px 0; } }

.password-eye {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 12px; }

.email-alert {
  color: #dd2c00;
  font-weight: 300;
  font-size: 12px;
  padding: 2px; }

.capslock-alert {
  color: #dd2c00;
  font-weight: 300;
  font-size: 12px;
  padding: 5px; }

.social-button {
  margin: 0;
  padding: 0 12px;
  text-transform: none;
  background-color: #f2f3f5;
  border-color: rgba(0, 0, 0, 0.2);
  color: #0d1216;
  font-weight: 400;
  font-size: 13px;
  text-transform: ""; }

.social-button:hover, .social-button:focus, .social-button:active {
  padding: 0 12px;
  text-transform: none;
  background-color: #e1e4e7;
  border-color: rgba(0, 0, 0, 0.2);
  color: #0d1216;
  font-weight: 400;
  font-size: 13px;
  text-transform: ""; }

.drop-down-icon {
  width: 18px;
  height: 18px;
  padding: 2px;
  cursor: pointer; }

.login-facebook {
  background-image: url("../images/login-facebook.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  padding: 2px; }

.login-google {
  background-image: url("../images/login-google1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  padding: 2px; }

.login-microsoft {
  background-image: url("../images/login-microsoft.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  padding: 2px; }

.unlogged.register md-content {
  padding-top: 10px;
  justify-content: center; }

.unlogged.register .padding-horizontal {
  padding-left: 16px;
  padding-right: 16px; }

.unlogged .form {
  min-width: 420px; }
  .unlogged .form .register-header {
    margin-top: 20px;
    color: #1a4585;
    font-weight: 400;
    font-size: 20px;
    text-transform: none; }
  .unlogged .form .md-subheader.md-default-theme.subheader-success, .unlogged .form .md-subheader.subheader-success {
    color: #1a4585;
    font-weight: 400;
    font-size: 20px;
    text-transform: none; }
  .unlogged .form .register-forgot-password {
    color: #757575;
    font-weight: 500;
    font-size: 13px;
    text-transform: none; }
  .unlogged .form .register-label {
    color: #757575;
    font-weight: 500;
    font-size: 13px;
    text-transform: none;
    line-height: 30px;
    opacity: 0.7; }
  .unlogged .form .terms-link {
    color: #757575;
    font-weight: 500;
    font-size: 13px;
    text-transform: none;
    text-decoration: none; }
  .unlogged .form md-checkbox .md-label {
    color: #757575;
    font-weight: 500;
    font-size: 13px;
    text-transform: none; }

.document-import tr.row-with-added-document {
  opacity: 0.3; }

.document-import tr.row-with-validation-error {
  background-color: #ffff99; }

.document-import .material-icons {
  cursor: pointer;
  font-size: 1.5rem; }
  .document-import .material-icons.imported-document-validation-errors {
    color: #9c9c63; }
  .document-import .material-icons.imported-document-ready {
    color: #3c763d; }
  .document-import .material-icons.imported-document-adding-error {
    color: #bd2c00; }
  .document-import .material-icons.imported-document-added {
    color: #3c763d; }

table.md-table.md-row-select td.md-cell:nth-child(2), table.md-table.md-row-select th.md-column:nth-child(2) {
  padding: 0 5px; }

table.md-table.md-row-select td.md-cell {
  cursor: pointer; }

#documents tr.document-details {
  background-color: #eee !important; }

#documents tbody tr.md-row:hover {
  background-color: #eee; }

.pagination {
  list-style-type: none;
  display: flex;
  justify-content: flex-end; }
  .pagination li {
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    color: #757575;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: 30px;
    line-height: 30px;
    margin: 0 3px;
    text-align: center;
    text-transform: none;
    vertical-align: middle;
    width: 30px; }
    .pagination li a {
      color: #757575;
      font-weight: 400;
      font-size: 14px;
      text-transform: "uppercase";
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: block; }
    .pagination li.active {
      background-color: #e2e2e2; }

@media screen and (max-width: 800px) {
  .pagination {
    padding-left: 0; } }

.doc-check-button {
  flex-wrap: wrap; }

.go-to {
  color: #757575;
  font-weight: 400;
  font-size: 13px;
  text-transform: none;
  margin: 0 15px;
  vertical-align: middle; }
  .go-to span.to-to-text {
    padding-top: 8px; }
  .go-to md-input-container.md-default-theme .md-input, .go-to md-input-container .md-input {
    border-color: #e2e2e2;
    height: 32px; }
  .go-to input {
    max-width: 50px;
    border: 1px solid #e2e2e2;
    margin-left: 10px; }

.md-scroll-mask {
  display: none; }

md-dialog.upload-text {
  width: 70%; }

md-dialog.extra-options {
  width: 50%; }

.extra-options-form {
  background-color: #f9f9f9; }
  .extra-options-form .price {
    text-decoration: underline; }
    .extra-options-form .price i {
      cursor: pointer; }
  @media (max-width: 960px) {
    .extra-options-form .option-description {
      margin-top: 1rem; } }
  @media (max-width: 600px) {
    .extra-options-form .option-price {
      margin-top: 1rem; } }
  .extra-options-form .aligned-checkbox {
    height: fit-content; }
  .extra-options-form .three-state-radio-wrapper .custom-label {
    margin-left: 1rem !important;
    font-weight: bold; }

@media (min-width: 992px) {
  md-dialog.priority-check {
    width: 30%; } }

md-dialog.priority-check .priority-check-description {
  text-align: justify; }

md-dialog.priority-check .priority-check-tokens-needed {
  font-size: large;
  margin-top: 1rem; }

md-dialog.priority-check .priority-check-tokens-needed-to-buy {
  font-size: large;
  font-weight: bold; }

i.options {
  vertical-align: text-bottom; }

md-dialog.jsa-document-edit-dialog {
  width: 70%; }
  md-dialog.jsa-document-edit-dialog md-autocomplete md-autocomplete-wrap {
    background-color: white; }

md-content.documents {
  overflow: none;
  background-color: #e1e3e6;
  display: inline-block;
  min-height: 325px;
  padding-left: 20px;
  padding-top: 25px;
  width: 100%; }
  md-content.documents.superadmin-docs {
    min-height: 75px !important; }
  md-content.documents header.header {
    display: block; }
    md-content.documents header.header h5 {
      color: #212121;
      font-weight: 300;
      font-size: 24px;
      text-transform: none;
      margin: 0; }
  md-content.documents .main {
    height: calc(100% - 240px);
    padding-top: 25px; }
  md-content.documents.hide {
    display: none; }

md-bottom-sheet.document-details {
  padding-top: 0;
  background-color: #2a6dd1;
  border: 0;
  color: #fff;
  font-size: 13px;
  text-align: right; }
  md-bottom-sheet.document-details .close-details {
    cursor: pointer; }
  md-bottom-sheet.document-details .detail-label {
    text-align: left;
    min-width: 200px; }
  md-bottom-sheet.document-details .detail-info {
    text-align: left;
    min-width: 100px; }

.grey-link-wrapper {
  text-align: center; }
  .grey-link-wrapper .grey-link {
    margin-bottom: 15px;
    display: inline-block;
    text-decoration: none;
    color: #4b4b4d;
    font-weight: 500;
    font-size: 11px;
    text-transform: uppercase; }
    .grey-link-wrapper .grey-link:hover {
      text-decoration: underline; }

.dragbox-dragndrop,
.dragbox-uploading,
.dragbox-success,
.dragbox-error {
  display: none; }

.dragbox-file {
  position: relative;
  left: -9999px;
  visibility: hidden; }

.dragbox {
  font-size: 1.25rem;
  /* 20 */
  background-color: rgba(0, 70, 170, 0.05);
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  display: inline-block;
  width: calc(100% - 20px);
  height: 120px; }
  .dragbox.dragbox-big {
    height: 160px; }

.dragbox-icon-cloud {
  color: #1a4585;
  width: 36px;
  height: 24px;
  margin-bottom: 7px;
  margin: 50px auto 10px auto; }
  .dragbox-icon-cloud .material-icons {
    font-size: 32px; }

.dragbox-input {
  text-align: center; }
  .dragbox-input .dragbox-disabled {
    opacity: 0.3; }

.dragbox.has-advanced-upload {
  border: 1px dashed #0046aa;
  border-radius: 4px; }

.dragbox.is-error {
  outline: 2px dashed #f44336;
  background-color: rgba(244, 67, 54, 0.05); }

.dragbox.is-dragover {
  background-color: rgba(255, 255, 255, 0.5); }

.dragbox-dragndrop,
.dragbox-icon {
  display: none; }

.dragbox.has-advanced-upload .dragbox-dragndrop {
  display: inline; }
  @media only screen and (max-width: 600px) {
    .dragbox.has-advanced-upload .dragbox-dragndrop {
      display: none; } }

.dragbox.has-advanced-upload .dragbox-icon {
  width: 100%;
  height: 80px;
  fill: #92b0b3;
  display: block;
  margin-bottom: 40px; }

.dragbox-error {
  display: none; }

.dragbox.is-error .dragbox-icon-cloud {
  background-image: url("../../images/upload_red.png"); }

.dragbox.is-error .dragbox-file + label {
  color: #f44336; }

.dragbox.is-error .dragbox-error {
  display: block;
  position: absolute;
  bottom: 5px;
  right: 0;
  left: 0;
  text-align: center;
  color: red;
  font-weight: 700;
  font-size: 12px;
  text-transform: none; }

.dragbox-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.dragbox-input label {
  max-width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  color: #0046aa;
  font-weight: 400;
  font-size: 20px;
  text-transform: none; }
  .dragbox-input label strong {
    font-weight: 700;
    text-decoration: underline; }

.dragbox-file + label:hover strong,
.dragbox-file:focus + label strong,
.dragbox-file.has-focus + label strong {
  cursor: pointer; }

.add-document-wrapper {
  display: none;
  background-color: #f9f9f9; }
  .add-document-wrapper md-toolbar.md-default-theme:not(.md-menu-toolbar), .add-document-wrapper md-toolbar:not(.md-menu-toolbar) {
    background-color: #1976d2; }
  .add-document-wrapper .add-document-text {
    color: #fff;
    font-weight: 100;
    font-size: 24px;
    text-transform: none;
    border-right: 1px solid #fff;
    margin-right: 40px; }
  @media screen and (max-width: 800px) {
    .add-document-wrapper .add-document-text {
      color: #fff;
      font-weight: 100;
      font-size: 14px;
      text-transform: none; } }
  .add-document-wrapper .add-file-text {
    color: #fff;
    font-weight: 100;
    font-size: 14px;
    text-transform: none; }
  .add-document-wrapper.active {
    display: inline-block;
    width: 100%; }
  .add-document-wrapper .add-document {
    padding: 0 20px; }
  .add-document-wrapper .document-title {
    color: #fff;
    font-weight: 100;
    font-size: 14px;
    text-transform: none;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 22px; }
  .add-document-wrapper .document-metric-title {
    margin-top: 30px; }
  .add-document-wrapper .document-metric-authors {
    margin-top: 10px; }
  .add-document-wrapper .document-metric-step {
    color: #696969;
    font-size: larger;
    font-weight: bold; }
  .add-document-wrapper .btn-flat, .add-document-wrapper .btn {
    min-width: 180px;
    text-align: center; }
  .add-document-wrapper .hr {
    margin: 25px 0; }
  .add-document-wrapper .coordinator-not-applicable-switch {
    margin-left: 0; }
    .add-document-wrapper .coordinator-not-applicable-switch .md-label {
      width: fit-content; }
      @media (min-width: 960px) {
        .add-document-wrapper .coordinator-not-applicable-switch .md-label {
          margin-left: 0; } }

documents-list md-content md-card button.md-button {
  color: #212121;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase; }

.add-without-checking-switch-text {
  font-weight: bold; }

#tableDocuments td.documents-report {
  width: 1px; }
  #tableDocuments td.documents-report .md-button {
    padding: 0; }
    #tableDocuments td.documents-report .md-button .jsa-report-complete {
      color: #A8193D; }
    #tableDocuments td.documents-report .md-button .jsa-report-accepted {
      color: #14b933; }

#tableDocuments.md-table td.md-cell:last-child, #tableDocuments.md-table th.md-column:last-child {
  padding: 0px 10px 0px 6px; }

#tableDocuments.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2), #tableDocuments.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
  padding: 0; }

#tableDocuments .documents-status {
  width: 60px; }

#tableDocuments .status-alert-block .icon-alphabet-alert-count {
  padding-top: 11px; }

#tableDocuments .document-client-details .status-alert-block .icon-alphabet-alert-count {
  padding-top: 6px; }

#tableDocuments .status-alert-cell .md-button.md-icon-button {
  width: 20px; }

.documents-buttons-report .jsa-search {
  margin-top: 20px;
  margin-left: 10px; }
  .documents-buttons-report .jsa-search #jsa-icon-search {
    color: #A8193D; }

.documents-buttons-report button.priority-check {
  color: #fff !important;
  background-color: #00C853 !important;
  line-height: normal !important; }
  .documents-buttons-report button.priority-check span {
    font-size: x-small;
    white-space: break-spaces;
    text-align: justify;
    width: min-content;
    padding-right: 2px;
    padding-left: 2px; }

span.check-id {
  font-weight: bold;
  font-size: 2rem; }

table.document-check-later-price-table td {
  width: 50%; }

.document-row-details {
  align-items: center; }

.jsa-examination-deprecated-warning {
  color: darkred; }

md-content.users {
  overflow: none;
  background-color: #e1e3e6;
  display: inline-block;
  min-height: 90px;
  padding-left: 20px;
  padding-top: 25px;
  width: 100%; }
  md-content.users header.header {
    display: block; }
    md-content.users header.header h5 {
      color: #212121;
      font-weight: 300;
      font-size: 24px;
      text-transform: none;
      margin: 0; }
  md-content.users .main {
    height: calc(100% - 240px);
    padding-top: 25px; }
  md-content.users.hide {
    display: none; }

header.header {
  display: block; }
  header.header h5 {
    color: #212121;
    font-weight: 300;
    font-size: 24px;
    text-transform: none;
    margin: 8px; }

#users md-autocomplete md-autocomplete-wrap {
  background: #fff !important; }

#users tbody .md-row:hover {
  background-color: #eee; }

table.md-table .md-column.user-query-counter-list-column {
  padding: 0 80px 0 0 !important; }

table.md-table .user-title-ellipsis {
  max-width: 160px;
  width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

table.md-table .table-details:hover {
  background-color: #eee; }

table.md-table .table-details {
  padding-top: 5px; }
  table.md-table .table-details td.md-cell {
    padding: 15px 8px 0 8px !important;
    vertical-align: top; }
    table.md-table .table-details td.md-cell md-menu-bar md-menu {
      margin-top: -10px !important; }
    table.md-table .table-details td.md-cell button {
      margin-top: -5px; }
  table.md-table .table-details .details-info {
    left: 7px;
    width: 99%;
    padding-left: 80px;
    box-sizing: border-box;
    border-top: 1px solid #d0d0d0;
    background-color: #eee !important;
    padding-bottom: 19px; }

@-moz-document url-prefix() {
  table.md-table .table-details .details-info {
    left: 0;
    width: 100%; } }
    table.md-table .table-details .details-info .detail-label {
      margin: 5px 0;
      color: #757575;
      font-weight: 400;
      font-size: 12px;
      text-transform: "none";
      line-height: 1; }
    table.md-table .table-details .details-info .detail-info {
      margin: 5px 0;
      color: #000;
      font-weight: 400;
      font-size: 12px;
      text-transform: "none";
      line-height: 1; }
    table.md-table .table-details .details-info .show-docs {
      margin-top: 20px;
      color: #1a4585;
      font-weight: 400;
      font-size: 12px;
      text-transform: "uppercase"; }

.user-query-counter-block {
  width: 100px;
  padding: 0;
  margin: 0; }

.user-query-counter-block input {
  padding: 0;
  margin: 2px; }

table.md-table .table-details td.md-cell .user-query-counter-block .input-block {
  margin-top: -15px; }

.user-query-counter-block md-input-container.md-default-theme .md-input, .user-query-counter-block md-input-container .md-input {
  height: 35px; }

.user-query-counter-block .md-errors-spacer {
  display: none; }

.user-query-counter-block .user-query-counter-input {
  width: 78px; }

.user-query-counter-block.text .input-block {
  display: none; }

.user-query-counter-block:hover .input-block {
  display: block; }

.user-query-counter-block .text-block {
  border: none;
  min-width: 40px;
  min-height: 20px; }

.user-query-counter-block:hover .text-block {
  display: none; }

.user-query-counter-block.editing .input-block {
  display: block; }

.user-query-counter-block.editing .text-block {
  display: none; }

.user-query-counter-block md-input-container > md-icon {
  left: 80px;
  top: 10px;
  font-size: 23px; }

.user-query-counter-block md-input-container > md-icon.icon-cancel {
  left: 105px; }

.user-query-counter-block md-input-container > md-icon.icon-edit {
  font-size: 23px; }

.user-query-counter-block md-icon {
  border-radius: 4px;
  border: 1px solid #eee; }

.user-query-counter-block md-icon:hover {
  border: 1px solid #bbb;
  background-color: #eee; }

.user-query-counter-block.reading .user-query-counter-progress {
  display: none; }

.user-query-counter-progress {
  top: -30px;
  left: 40px; }

.user-query-counter-block .text-block.no-content {
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 30px; }

.site-container .loader.loader-user-import {
  top: 80px;
  width: auto;
  left: 40px; }

.participation-info {
  padding: 15px 0 10px 18px; }

.no-participation-part2 {
  font-size: 0.8em;
  color: #555; }

.due-date md-input-container md-datepicker .md-datepicker-input-container .md-datepicker-input {
  min-width: 100px; }

.payments {
  height: 100%; }
  .payments .payment-content-step1 input::-webkit-outer-spin-button,
  .payments .payment-content-step1 input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */ }
  .payments .payment-content-step1 input[type='number'] {
    -moz-appearance: textfield; }
  .payments .payment-content-step1 input::-webkit-outer-spin-button,
  .payments .payment-content-step1 input::-webkit-inner-spin-button {
    -webkit-appearance: none; }
  .payments .md-headline {
    font-weight: 300; }
  .payments md-content {
    background-color: #f9f9f9; }
  .payments md-content.payments-content {
    display: inline-block;
    min-height: 85px;
    padding-left: 30px;
    padding-top: 25px;
    width: 100%;
    background-color: #e1e3e6; }
    .payments md-content.payments-content header.header h5 {
      color: #212121;
      font-weight: 300;
      font-size: 24px;
      text-transform: none;
      margin: 0; }
  @media screen and (max-width: 800px) {
    .payments .payment-metric .payment-metric-container {
      display: flex;
      width: 100%;
      flex-wrap: wrap; } }
  .payments .payment-metric md-toolbar.md-default-theme.md-hue-2:not(.md-menu-toolbar), .payments .payment-metric md-toolbar.md-hue-2:not(.md-menu-toolbar) {
    background-color: #1976d2; }
  .payments .payment-metric .md-button.md-default-theme.md-primary.md-fab, .payments .payment-metric .md-button.md-primary.md-fab, .payments .payment-metric .md-button.md-default-theme.md-primary.md-raised, .payments .payment-metric .md-button.md-primary.md-raised {
    background-color: #1976d2; }
  .payments .payment-metric md-card md-card-title {
    cursor: pointer;
    padding: 0; }
  .payments .payment-metric md-radio-button .md-label img {
    vertical-align: middle; }
  .payments md-card md-card-title + md-card-content {
    padding: 20px 0 0 0;
    margin-bottom: 20px;
    border-top: 1px solid rgba(186, 186, 186, 0.25);
    border-bottom: 1px solid rgba(186, 186, 186, 0.25); }
    .payments md-card md-card-title + md-card-content md-checkbox {
      margin-bottom: 0; }
  .payments md-card md-card-title {
    cursor: pointer; }
  .payments table.md-table td.md-cell, .payments table.md-table th.md-column {
    padding: 0 10px; }
  .payments table.md-table td.md-cell:nth-child(1), .payments table.md-table th.md-column:nth-child(1) {
    padding-left: 30px; }
  .payments table.md-table tbody.md-body > tr.md-row {
    height: 42px; }
  .payments table.md-table tfoot.md-foot > tr.md-row {
    height: 15px; }

.payments-methods-list {
  display: block;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  text-transform: none;
  margin: 20px 0;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: contain; }
  .payments-methods-list .payment-method {
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle; }
    .payments-methods-list .payment-method.payment-method-currency {
      height: 30px;
      width: 60px; }
    .payments-methods-list .payment-method.TYPE_TRANSFER {
      background-image: url("../images/pay-przelewy24.png");
      padding: 10px 0 0 80px;
      height: 30px; }
    .payments-methods-list .payment-method.TYPE_PAY_PAL {
      background-image: url("../images/pay-paypal.png");
      padding: 10px 0 0 80px;
      height: 30px; }
    .payments-methods-list .payment-method.TYPE_PAY_PAL_RO {
      background-image: url("../images/pay-paypal.png");
      padding: 10px 0 0 80px;
      height: 30px; }
    .payments-methods-list .payment-method.TYPE_SMS {
      background-image: url("../images/pay-smartpay.png");
      padding: 10px 0 0 130px;
      height: 30px; }
    .payments-methods-list .payment-method.TYPE_MOBILPAY_CARD {
      background-image: url("../images/pay-mobilpay-card.png");
      padding: 0 0 0 220px;
      height: 20px;
      margin-top: -10px; }
    .payments-methods-list .payment-method.TYPE_MOBILPAY_SMS {
      background-image: url("../images/pay-mobilpay.png");
      height: 30px;
      width: 75px;
      background-size: contain;
      padding: 0;
      margin-top: -10px; }
    .payments-methods-list .payment-method.TYPE_MOBILPAY_SMS_RO {
      background-image: url("../images/pay-mobilpay-ro.png");
      height: 30px;
      width: 250px;
      background-size: contain;
      padding: 0;
      margin-top: -10px; }
    .payments-methods-list .payment-method.TYPE_EASY_PAY {
      background-image: url("../images/pay-easypay.png");
      height: 30px;
      width: 120px;
      background-size: contain;
      padding: 0;
      margin-top: -10px; }
    .payments-methods-list .payment-method.TYPE_KASSA_24 {
      background-image: url("../images/pay-kassa24.png");
      height: 30px;
      width: 120px;
      background-size: contain;
      padding: 0;
      margin-top: -10px; }
    .payments-methods-list .payment-method.TYPE_KASSA_24_CARD {
      background-image: url("../images/pay-kassa24card.png");
      height: 30px;
      width: 120px;
      background-size: contain;
      padding: 0;
      margin-top: -10px; }
    .payments-methods-list .payment-method.TYPE_BLIK {
      background-image: url("../images/pay-blik.png");
      height: 30px;
      width: 120px;
      background-size: contain;
      padding: 0;
      margin-top: -10px; }
    .payments-methods-list .payment-method.TYPE_MILLIKART_CARD {
      background-image: url("../images/pay-millikart.png");
      height: 40px;
      width: 56px;
      background-size: contain;
      padding: 0;
      margin-top: -10px; }
    .payments-methods-list .payment-method.TYPE_YIGIM_AZ {
      background-image: url("../images/pay-yigim.png");
      height: 40px;
      width: 56px;
      background-size: contain;
      padding: 0;
      margin-top: -10px; }
    .payments-methods-list .payment-method.TYPE_EPAY_BG {
      background-image: url("../images/pay-epay_bg.png");
      height: 40px;
      width: 56px;
      background-size: contain;
      padding: 0;
      margin-top: -10px; }
    .payments-methods-list .payment-method.TYPE_FORTE_KZ {
      background-image: url("../images/pay-forte-kz.png");
      height: 40px;
      width: 56px;
      background-size: contain;
      padding: 0;
      margin-top: -10px; }

.payment-col-label {
  color: #000;
  font-weight: 500;
  font-size: 12px;
  text-transform: none;
  padding-top: 10px; }

.payment-col-content {
  color: #000;
  font-weight: 400;
  font-size: 21px;
  text-transform: none; }

.payment-text {
  color: grey;
  font-weight: 400;
  font-size: 13px;
  text-transform: none;
  line-height: 1.7; }

.payment-box {
  width: 100%;
  display: inline-block;
  border-radius: 4px;
  overflow: hidden;
  padding-top: 0;
  position: relative; }
  @media only screen and (max-width: 992px) {
    .payment-box {
      width: 100%;
      min-height: 1px; } }
  .payment-box-header {
    padding-left: 30px;
    line-height: 60px;
    color: #fff;
    font-weight: 400;
    font-size: 21px;
    text-transform: none; }
  .payment-box .payment-top-wrapper {
    position: relative; }
  .payment-box .payment-line {
    content: '';
    background-color: #bababa;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 30px;
    right: 30px; }
    @media only screen and (max-width: 600px) {
      .payment-box .payment-line {
        display: none; } }
  .payment-box .payment-steps {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 10px;
    position: relative; }
    @media only screen and (max-width: 600px) {
      .payment-box .payment-steps {
        padding: 20px 0; } }

.step {
  padding: 0 20px;
  background-color: #fff;
  z-index: 10; }
  @media only screen and (max-width: 600px) {
    .step {
      display: none;
      padding: 0 15px; } }
  .step-counter {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #bababa;
    text-align: center;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    text-transform: none; }
  .step-name {
    display: inline-block;
    vertical-align: middle;
    color: #bababa;
    font-weight: 400;
    font-size: 12px;
    text-transform: none; }

.payment-box-content {
  margin: 0;
  padding: 0; }

.payment-content {
  padding: 0 30px;
  width: 100%;
  box-sizing: border-box; }
  @media only screen and (max-width: 600px) {
    .payment-content {
      padding: 15px; } }

.tokens-count input {
  font-size: 38px;
  height: 4rem;
  padding-top: 5px;
  font-size: 38px; }

.summary {
  padding: 15px 30px 10px;
  margin: 0 -30px;
  background-color: #f6f6f6; }
  .summary i.material-icons {
    cursor: pointer; }
  .summary md-select {
    margin: 0; }
  .summary .hr {
    margin: 0 0 15px; }
  .summary-line {
    width: 100%;
    display: inline-block;
    margin: 0; }
  .summary-name {
    color: rgba(75, 75, 77, 0.8);
    font-weight: 500;
    font-size: 12px;
    float: left; }
  .summary-value {
    float: right;
    font-size: 15px;
    color: #757575;
    font-weight: 500;
    text-transform: uppercase; }
    .summary-value.summary-value-big {
      font-size: 18px;
      color: #000;
      margin-top: -4px; }

@media screen and (max-width: 800px) {
  .summary {
    margin: 0 -15px;
    box-sizing: border-box; } }

.payment-checkbox {
  margin: 20px 0; }
  .payment-checkbox label {
    height: 16px;
    line-height: 16px; }

.hr-payment {
  margin: 20px -30px !important;
  border-color: rgba(186, 186, 186, 0.25) !important; }

.step-counter-done {
  display: none;
  font-size: 12px;
  margin-top: -2px; }

.active-step1 .steps-step-1 .step-counter,
.active-step2 .steps-step-2 .step-counter,
.active-step3 .steps-step-3 .step-counter {
  background-color: #1976D2; }

.active-step1 .steps-step-1 .step-name,
.active-step2 .steps-step-2 .step-name,
.active-step3 .steps-step-3 .step-name {
  color: #000;
  font-weight: 500; }

@media only screen and (max-width: 600px) {
  .active-step1 .steps-step-1,
  .active-step2 .steps-step-2,
  .active-step3 .steps-step-3 {
    display: inline-block; } }

.active-step2 .steps-step-1 .step-counter,
.active-step3 .steps-step-1 .step-counter,
.active-step3 .steps-step-2 .step-counter {
  background-color: #1976D2; }

.active-step2 .steps-step-1 .step-counter-number,
.active-step3 .steps-step-1 .step-counter-number,
.active-step3 .steps-step-2 .step-counter-number {
  display: none; }

.active-step2 .steps-step-1 .step-counter-done,
.active-step3 .steps-step-1 .step-counter-done,
.active-step3 .steps-step-2 .step-counter-done {
  display: inline-block; }

.active-step1 .payment-content-step1,
.active-step2 .payment-content-step2,
.active-step3 .payment-content-step3 {
  display: inline-block; }

.active-step1 #tokensPrev {
  display: none; }

.active-step3 #tokensNext {
  display: none; }

#tokensEnd {
  display: none; }

.active-step3 #tokensEnd {
  display: inline-block; }

.payment-content-step2 .input-field input {
  height: 2rem;
  padding-top: 5px;
  margin-bottom: 5px; }

.payment-content-step2 .hr {
  margin: 0 -30px;
  border-color: rgba(186, 186, 186, 0.25); }

.payment-content-step3 {
  padding-top: 0;
  padding-bottom: 5px; }
  .payment-content-step3 .hr {
    margin: 10px -30px;
    border-color: rgba(186, 186, 186, 0.25); }
  @media only screen and (max-width: 600px) {
    .payment-content-step3 {
      margin-bottom: 20px; } }

.payment-mode-header {
  color: rgba(75, 75, 77, 0.8);
  font-weight: 500;
  font-size: 12px;
  margin-top: 15px; }

.payment-mode .payment-mode-line {
  height: auto; }
  .payment-mode .payment-mode-line img {
    max-height: 30px; }

.payment-mode-line {
  height: 50px;
  padding: 10px 0;
  border-bottom: 1px solid rgba(186, 186, 186, 0.25); }
  .payment-mode-line:last-of-type {
    border-bottom: none; }

.payment-mode-img {
  height: 30px;
  width: 80px;
  display: inline-block; }
  .payment-mode-img img {
    height: 100%; }

.payment-box-footer {
  position: absolute;
  bottom: 30px;
  right: 30px; }
  @media only screen and (max-width: 600px) {
    .payment-box-footer {
      display: inline-block;
      width: 100%;
      padding: 0 30px;
      position: unset;
      margin-top: -10px; } }
  .payment-box-footer .btn, .payment-box-footer .btn-flat {
    width: 140px;
    text-align: center; }
    @media only screen and (max-width: 600px) {
      .payment-box-footer .btn, .payment-box-footer .btn-flat {
        width: 50%;
        display: inline-block;
        float: left; } }
  @media only screen and (max-width: 600px) {
    .payment-box-footer .btn {
      float: right; } }

.payment-left {
  display: inline-block;
  vertical-align: middle;
  width: 60%; }
  @media only screen and (max-width: 1280px) {
    .payment-left {
      width: 485px; }
    @-moz-document url-prefix() {
      .payment-left {
        width: 460px; } } }
  @media only screen and (max-width: 992px) {
    .payment-left {
      width: 100%;
      max-width: 100%; } }

.payment-line {
  content: '';
  background-color: #bababa;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 30px;
  right: 30px; }
  @media only screen and (max-width: 600px) {
    .payment-line {
      display: none; } }

.payment-info {
  display: none;
  vertical-align: top;
  padding-top: 115px;
  padding-left: 30px; }
  .payment-info.active {
    display: inline-block; }
  @media only screen and (max-width: 1280px) {
    .payment-info {
      width: 300px; }
    @-moz-document url-prefix() {
      .payment-info {
        width: 250px; } }
      .payment-info div:not(:last-of-type) br {
        display: none; } }
  @media only screen and (max-width: 992px) {
    .payment-info {
      padding-top: 25px;
      width: auto; } }
  .payment-info .payment-col-label {
    margin-bottom: 10px; }
  .payment-info .hr {
    margin-left: 0;
    margin-right: 0; }

.payment-history {
  margin-top: 20px;
  background-color: white; }
  .payment-history .collapsible-header {
    background-color: transparent;
    height: 80px;
    line-height: 80px; }
    .payment-history .collapsible-header .collapsible-header-text {
      color: #000;
      font-weight: 300;
      font-size: 24px;
      text-transform: none; }
    .payment-history .collapsible-header .collapsible-text {
      color: #696969;
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase; }
    .payment-history .collapsible-header .material-icons {
      float: right;
      line-height: 80px; }
  .payment-history .collapsible-body {
    padding-bottom: 20px; }
  .payment-history .collapsible-opened {
    display: none; }
  .payment-history .active .collapsible-closed {
    display: none; }
  .payment-history .active .collapsible-opened {
    display: inline-block; }

.file-download i.material-icons {
  font-size: 18px;
  color: #696969; }

.file-download:hover {
  cursor: pointer; }
  .file-download:hover i.material-icons {
    opacity: 0.75; }

.payment-line-collapsible {
  line-height: 0; }
  .payment-line-collapsible .collapsible {
    line-height: 1.5;
    border: none;
    box-shadow: none;
    margin-bottom: 0; }
    .payment-line-collapsible .collapsible .collapsible-header {
      height: 0;
      min-height: 0;
      border: none; }
    .payment-line-collapsible .collapsible .collapsible-body {
      padding-top: 15px;
      border: none; }
      .payment-line-collapsible .collapsible .collapsible-body .datepicker-m-y {
        margin-bottom: 0; }
      .payment-line-collapsible .collapsible .collapsible-body .select-text {
        font-size: 0.8rem;
        color: rgba(75, 75, 77, 0.8);
        font-weight: 500;
        position: relative;
        top: -1px; }
      .payment-line-collapsible .collapsible .collapsible-body .date-row .datepicker-m-y {
        width: 100%;
        height: 38px; }

.sms-info-text {
  margin-bottom: 30px; }

.payment-fv {
  width: 420px;
  vertical-align: top;
  padding-top: 45px;
  padding-left: 30px;
  display: none;
  pointer-events: none;
  position: relative;
  opacity: 0.6; }
  .payment-fv.active {
    display: inline-block; }
  .payment-fv .fv-text {
    color: rgba(75, 75, 77, 0.8);
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 30px; }
  @media only screen and (max-width: 1280px) {
    .payment-fv {
      width: 240px; }
    @-moz-document url-prefix() {
      .payment-fv {
        width: 250px; } }
      .payment-fv .input-field {
        width: 100% !important; } }

.doc-tokens-info {
  vertical-align: middle;
  display: inline-block;
  color: #696969;
  font-size: 13px;
  margin-right: 0 !important; }

.md-label {
  vertical-align: middle; }

.expiry-date-info {
  color: #777777; }

.blik-block {
  margin-top: 15px; }

.last-payment-status tr:nth-child(even) {
  background-color: #f2f2f2; }

.last-payment-status table {
  margin: 10px; }

.payment-rejected .material-icons {
  color: red; }

.payment-cancelled .material-icons {
  color: #ff8100; }

.payment-reclamation .material-icons {
  color: #ff8100; }

.payment-done .material-icons {
  color: green; }

.last-payment-info .material-icons {
  font-size: 1.8em; }

.translations .align-items-baseline {
  align-items: baseline; }

.translations table.translations-table {
  width: fit-content;
  table-layout: fixed; }
  .translations table.translations-table th:nth-of-type(2) {
    width: 2rem; }
  .translations table.translations-table .translationType {
    width: 4rem !important; }
  .translations table.translations-table th:not(:nth-of-type(1)):not(:nth-of-type(2)) {
    width: 20rem; }
  .translations table.translations-table span md-icon.icon-edit {
    font-size: 1rem;
    max-width: 1rem;
    min-width: 1rem;
    max-height: 1rem;
    min-height: 1rem; }
  .translations table.translations-table md-icon.icon-save, .translations table.translations-table md-icon.icon-cancel {
    margin: 0; }
  .translations table.translations-table td span.translation-text {
    display: inline-block;
    word-break: break-word;
    max-width: 90%; }
  .translations table.translations-table td md-input-container {
    width: 90%; }
  .translations table.translations-table md-icon:hover {
    border: 1px solid #bbb;
    background-color: #eee; }

.translation-history .createDate {
  width: 10rem; }

.translation-history .userEmail {
  width: 15rem; }

.translation-history .status {
  width: 10rem; }

th.lang {
  width: 20rem; }

.new {
  background-color: rgba(200, 220, 120, 0.7); }

.changed {
  background-color: rgba(230, 145, 200, 0.7); }

.previous {
  background-color: rgba(230, 15, 20, 0.7); }

.info {
  min-height: auto;
  background: #f9f9f9;
  display: block; }
  .info md-content.info-header {
    overflow: none;
    background-color: #e1e3e6;
    display: inline-block;
    min-height: 85px;
    padding-left: 30px;
    padding-top: 25px;
    padding-bottom: 0;
    width: 100%; }
    .info md-content.info-header header.header {
      display: block; }
      .info md-content.info-header header.header h5 {
        color: #212121;
        font-weight: 300;
        font-size: 24px;
        text-transform: none;
        margin: 0; }
  .info .info-container {
    overflow: inherit;
    min-height: auto;
    background: #f9f9f9; }
    .info .info-container h5 {
      height: 50px;
      padding-left: 30px;
      padding-top: 15px;
      padding-bottom: 15px;
      color: #212121;
      font-weight: 300;
      font-size: 24px;
      text-transform: none;
      margin: 0; }
    .info .info-container .instructions-list {
      padding-left: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #d1d1d1; }
      .info .info-container .instructions-list a {
        vertical-align: middle;
        text-decoration: none;
        color: #757575;
        font-weight: 500;
        font-size: 13px;
        text-transform: uppercase; }
        .info .info-container .instructions-list a i {
          color: #757575;
          font-weight: normal;
          font-size: 16px;
          text-transform: none; }
        .info .info-container .instructions-list a span {
          margin-left: 5px;
          display: inline; }
      .info .info-container .instructions-list.admin-operator {
        padding-top: 30px; }
    .info .info-container .faq-list ul {
      list-style-type: none;
      padding: 0;
      width: 100%;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
      .info .info-container .faq-list ul li .collapsible-header {
        display: block;
        color: #696969;
        font-weight: 500;
        font-size: 14px;
        text-transform: none;
        cursor: pointer;
        min-height: 3rem;
        line-height: 3rem;
        padding: 0 1rem;
        background-color: #fff;
        border-bottom: 1px solid #ddd; }
        .info .info-container .faq-list ul li .collapsible-header.without-border {
          border-bottom: 0; }
      .info .info-container .faq-list ul li .collapsible-body {
        display: none;
        color: #696969;
        font-weight: normal;
        font-size: 13px;
        text-transform: none;
        border-bottom: 1px solid #ddd;
        box-sizing: border-box;
        padding: 0 1rem 2rem 1rem; }
    .info .info-container .contact-form {
      padding: 0 30px 30px 30px; }
      .info .info-container .contact-form .max-size {
        color: #DD2C00;
        font-weight: 500;
        font-size: "";
        text-transform: none; }
      .info .info-container .contact-form form {
        width: 100%; }
        .info .info-container .contact-form form .message label {
          padding-bottom: 5px; }
      .info .info-container .contact-form button.md-button {
        height: 40px;
        line-height: 40px;
        padding: 0 2rem;
        color: #343434;
        font-weight: 500;
        font-size: "";
        text-transform: none; }
        .info .info-container .contact-form button.md-button.button-attach {
          color: #478cff;
          font-weight: 500;
          font-size: "";
          text-transform: none; }
        .info .info-container .contact-form button.md-button.button-send {
          color: #fff;
          font-weight: 500;
          font-size: "";
          text-transform: none;
          background: #478cff; }
    .info .info-container .contact-form-title {
      padding: 30px 30px 15px; }
      .info .info-container .contact-form-title span {
        font-size: 25px;
        font-weight: 300; }
  .info #faq {
    margin-top: 0; }
  .info .faq-row {
    padding: 0 30px 30px 30px;
    border-bottom: 1px solid #d1d1d1; }
  .info ul.contact-form-attachments {
    column-count: 3;
    list-style-type: none; }
    .info ul.contact-form-attachments li {
      color: gray; }

#clients md-content.clients-header {
  overflow: none;
  background-color: #e1e3e6;
  display: inline-block;
  padding-left: 20px;
  padding-top: 25px;
  width: 100%;
  min-height: 80px; }
  #clients md-content.clients-header header.header {
    display: block; }
    #clients md-content.clients-header header.header h5 {
      color: #212121;
      font-weight: 300;
      font-size: 24px;
      text-transform: none;
      margin: 0; }

form.client-finders-configuration table.finders-table {
  width: fit-content; }
  form.client-finders-configuration table.finders-table th.finder-enabled {
    width: 20px; }
  form.client-finders-configuration table.finders-table th.finder-visible {
    width: 30px; }

.associated-study-delete-button {
  min-height: 1.5rem;
  height: 1.5rem !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: left; }
  .associated-study-delete-button i {
    color: #bd2c00;
    font-size: 1.5rem;
    vertical-align: top; }

.popup-details-info {
  width: 99%;
  padding-left: 20px;
  box-sizing: border-box;
  border-top: 1px solid #d0d0d0; }

@-moz-document url-prefix() {
  .popup-details-info {
    left: 0;
    width: 100%; } }
  .popup-details-info .detail-label {
    margin: 4px 0;
    color: #757575;
    font-weight: 400;
    font-size: 12px;
    text-transform: "none";
    line-height: 1; }
  .popup-details-info .detail-info {
    margin: 4px 0;
    color: #000;
    font-weight: 400;
    font-size: 12px;
    text-transform: "none";
    line-height: 1; }

md-toolbar.navbar {
  background: #0d47a1;
  color: rgba(207, 216, 220, 0.9);
  font-weight: "";
  font-size: 11px;
  text-transform: ""; }
  md-toolbar.navbar .navbar-container {
    max-height: 60px; }
    md-toolbar.navbar .navbar-container .navbar-logo {
      background: url("../images/login-logo.png") no-repeat;
      background-size: contain !important;
      width: 100%;
      height: 35px;
      margin: 5px;
      margin-left: 20px; }
    md-toolbar.navbar .navbar-container .logo-img {
      height: 35px;
      margin: 0;
      margin-left: 20px; }
    md-toolbar.navbar .navbar-container .session-countdown {
      margin: 0 20px; }
    md-toolbar.navbar .navbar-container md-input-container {
      position: relative;
      border-radius: 4px;
      margin-top: 5px;
      background: #265aab; }
      md-toolbar.navbar .navbar-container md-input-container .material-icons {
        position: absolute;
        top: 10px;
        left: 5px;
        color: #bbc9d7;
        font-weight: "";
        font-size: 24px;
        text-transform: ""; }
      md-toolbar.navbar .navbar-container md-input-container input.md-input {
        height: 35px;
        border: 0;
        vertical-align: middle;
        color: #ddd;
        font-weight: "";
        font-size: 15px;
        text-transform: "";
        padding-left: 30px; }
        md-toolbar.navbar .navbar-container md-input-container input.md-input::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #ddd;
          font-weight: "";
          font-size: 14px;
          text-transform: ""; }
        md-toolbar.navbar .navbar-container md-input-container input.md-input::-moz-placeholder {
          /* Firefox 19+ */
          color: #ddd;
          font-weight: "";
          font-size: 14px;
          text-transform: ""; }
        md-toolbar.navbar .navbar-container md-input-container input.md-input:-ms-input-placeholder {
          /* IE 10+ */
          color: #ddd;
          font-weight: "";
          font-size: 14px;
          text-transform: ""; }
        md-toolbar.navbar .navbar-container md-input-container input.md-input:-moz-placeholder {
          /* Firefox 18- */
          color: #ddd;
          font-weight: "";
          font-size: 14px;
          text-transform: ""; }
    md-toolbar.navbar .navbar-container md-menu {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      margin-top: 5px; }
      md-toolbar.navbar .navbar-container md-menu button.md-button {
        height: 25px;
        line-height: 25px;
        margin-bottom: 3px;
        margin-top: 3px;
        min-height: 25px;
        text-transform: none;
        vertical-align: middle; }
        md-toolbar.navbar .navbar-container md-menu button.md-button i {
          vertical-align: middle; }

md-menu-content md-menu-item button.md-button {
  text-transform: none;
  vertical-align: middle; }
  md-menu-content md-menu-item button.md-button i {
    vertical-align: middle; }

.md-button.md-default-theme.md-accent, .md-button.md-accent {
  color: #f44336; }

md-nav-bar.md-default-theme md-nav-ink-bar, md-nav-bar md-nav-ink-bar {
  color: #f44336;
  background: #f44336; }

.logoutToRootInfo {
  font-size: 12px;
  font-style: italic; }

md-toolbar.navbar .navbar-container md-menu button.md-button.logoutToRootInfoBtn {
  line-height: 16px;
  height: 40px; }

.md-sidenav-left {
  background: #303338; }
  .md-sidenav-left md-content {
    padding: 0;
    margin: 0;
    background: transparent;
    color: rgba(207, 216, 220, 0.5);
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase; }
    .md-sidenav-left md-content .sidenav-header {
      padding: 0; }
      .md-sidenav-left md-content .sidenav-header i {
        cursor: pointer;
        color: #fff;
        font-weight: "";
        font-size: 26px;
        text-transform: ""; }
    .md-sidenav-left md-content ul.sidenav-menu {
      padding: 0;
      margin: 10px 0 20px 0; }
      .md-sidenav-left md-content ul.sidenav-menu .sidenav-lang {
        display: block;
        margin-top: 60%;
        bottom: 50%;
        width: 100%; }
        .md-sidenav-left md-content ul.sidenav-menu .sidenav-lang .md-button {
          margin: 0; }
      .md-sidenav-left md-content ul.sidenav-menu .sidenav-profile {
        display: block;
        margin-top: 60%;
        bottom: 20%;
        width: 100%; }
      .md-sidenav-left md-content ul.sidenav-menu li {
        padding: 0;
        margin: 0; }
        .md-sidenav-left md-content ul.sidenav-menu li a.md-button {
          width: 100%;
          min-width: 68px;
          box-sizing: border-box;
          margin: 0;
          padding: 5px;
          border-radius: 0;
          vertical-align: middle;
          text-align: left;
          border-left: 2px solid #25282b;
          color: #cfd8dc;
          font-weight: 400;
          font-size: 14px;
          text-transform: none; }
          .md-sidenav-left md-content ul.sidenav-menu li a.md-button i {
            vertical-align: middle;
            padding: 0 10px; }
          .md-sidenav-left md-content ul.sidenav-menu li a.md-button.collapsed {
            text-align: center; }
            .md-sidenav-left md-content ul.sidenav-menu li a.md-button.collapsed i {
              padding: 0; }
          .md-sidenav-left md-content ul.sidenav-menu li a.md-button.active {
            border-left: 2px solid #28528f;
            background: #25282b; }
            .md-sidenav-left md-content ul.sidenav-menu li a.md-button.active i {
              color: #fff;
              font-weight: 400;
              font-size: 26px;
              text-transform: none; }
    .md-sidenav-left md-content md-divider {
      border-color: #25282b; }
    .md-sidenav-left md-content .tokens {
      color: rgba(207, 216, 220, 0.5);
      font-weight: 400;
      font-size: 12px;
      text-transform: none; }
      .md-sidenav-left md-content .tokens h3 {
        padding: 10px 0;
        color: rgba(207, 216, 220, 0.5);
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase; }
      .md-sidenav-left md-content .tokens .tokens-info {
        text-align: right;
        color: #cfd8dc;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase; }
    .md-sidenav-left md-content .contract {
      width: 200px;
      max-width: 200px; }
      .md-sidenav-left md-content .contract h3 {
        margin-top: 0;
        margin-block-end: 8px; }
      .md-sidenav-left md-content .contract .contract-group {
        line-height: 1.8; }
        .md-sidenav-left md-content .contract .contract-group:last-child {
          margin-bottom: 0; }
        .md-sidenav-left md-content .contract .contract-group .row {
          padding: 0; }
      .md-sidenav-left md-content .contract .pool-label {
        color: rgba(207, 216, 220, 0.5);
        font-weight: 300;
        font-size: 12px;
        text-transform: none; }
      .md-sidenav-left md-content .contract .contract-value {
        text-align: right;
        color: #fff;
        font-weight: 300;
        font-size: 12px;
        text-transform: none; }
        .md-sidenav-left md-content .contract .contract-value .contract-limit {
          padding: 0; }
      .md-sidenav-left md-content .contract .contract-info {
        color: rgba(207, 216, 220, 0.5);
        font-weight: 500;
        font-size: 12px;
        text-transform: none; }
      .md-sidenav-left md-content .contract .progress-bar-usage .md-container {
        background-color: #1c1e21;
        border-radius: 10px; }

.pl .progress-bar-usage .md-container .md-bar2 {
  background-color: #135ed3; }

.ro .progress-bar-usage .md-container .md-bar2, .en .progress-bar-usage .md-container .md-bar2 {
  background-color: #A31D21; }

.error-description {
  font-weight: 300;
  font-size: 12px;
  text-transform: none;
  opacity: 0.75;
  color: red;
  line-height: 2; }

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px; }

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1; }

.table-container table.md-table td:first-child.lp, .table-container table.md-table th:first-child.lp {
  text-align: center;
  width: 40px; }

.column-datetime {
  min-width: 110px; }

.table-container table.md-table th.md-column.narrow {
  width: 1px;
  white-space: nowrap;
  padding: 0 0px; }

table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2), table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
  padding: 0 0.5rem 0 0; }

table.md-table:not(.md-row-select) td.md-cell:first-child, table.md-table:not(.md-row-select) th.md-column:first-child {
  padding: 0 0.5rem; }

.landing {
  display: block; }
  .landing.landing-image .landing-header .landing-header-logo-bg {
    background-color: transparent;
    width: 200px;
    padding: 10px 30px;
    box-sizing: content-box; }
    .landing.landing-image .landing-header .landing-header-logo-bg .landing-header-logo {
      display: block;
      background-image: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 70px;
      width: 100%; }
  .landing.landing-image .landing-header .menu-lang {
    margin-top: 30px; }
  .landing.landing-image .landing-content {
    padding: 10px 40px 50px 40px;
    background: #fff;
    opacity: 0.85;
    color: #000;
    font-weight: 300;
    font-size: 14px;
    text-transform: none; }
    .landing.landing-image .landing-content span {
      color: #000;
      font-weight: 300;
      font-size: 14px;
      text-transform: none; }
    .landing.landing-image .landing-content .landing-title {
      color: #1a4585;
      font-weight: 400;
      font-size: 20px;
      text-transform: none; }
    .landing.landing-image .landing-content .landing-checkout, .landing.landing-image .landing-content .landing-list {
      display: block;
      margin: 25px 0; }
    .landing.landing-image .landing-content .landing-red-mark {
      display: block;
      color: #DD2C00;
      font-weight: 500;
      font-size: 18px;
      text-transform: none;
      border: 5px solid #DD2C00;
      width: 260px;
      transform: rotateZ(-25deg);
      padding: 20px;
      border-radius: 15px;
      text-align: center;
      line-height: 1;
      margin-top: 25px; }
      .landing.landing-image .landing-content .landing-red-mark .md-subhead {
        font-size: 14px; }
  .landing.landing-image .landing-form .dragbox-input label {
    white-space: normal; }
  @media (min-width: 960px) {
    .landing.landing-image .landing-form {
      border-left: 0.7rem;
      border-bottom: 0;
      border-right: 0;
      border-bottom: 0;
      border-style: solid;
      border-color: transparent; } }
  .landing.landing-image .landing-form .landing-title span {
    display: block;
    padding-bottom: 0;
    color: #1a4585;
    font-weight: 300;
    font-size: 14px;
    text-transform: none; }
    .landing.landing-image .landing-form .landing-title span h3 {
      text-align: center;
      color: #1a4585;
      font-weight: 300;
      font-size: 16px;
      text-transform: none; }
  .landing.landing-image .landing-form .landing-title .landing-header-text {
    font-weight: 300; }
  .landing.landing-image .landing-form .landing-file-name .file-name {
    margin-right: 0;
    padding-right: 0;
    max-width: 20rem; }
  .landing.landing-image .landing-form .landing-file-name .delete-button {
    min-width: 2rem;
    margin: 0;
    padding: 0;
    color: #DD2C00; }
  .landing.landing-image .landing-form .landing-total-price {
    font-size: 20px;
    text-decoration: underline; }
  .landing.landing-image .landing-form .landing-button {
    font-size: 22px;
    color: #DD2C00; }
  .landing.landing-image .landing-form md-content .btn-attach {
    color: #DD2C00;
    font-weight: 500;
    font-size: 18px;
    text-transform: ""; }
  .landing.landing-image .landing-form .landing-inputs span, .landing.landing-image .landing-form .landing-middle span {
    font-size: 14px;
    font-weight: 300; }
  .landing.landing-image .landing-form .landing-inputs input, .landing.landing-image .landing-form .landing-middle input {
    font-size: 14px;
    font-weight: 300; }
  .landing .landing-info {
    padding: 20px;
    background-color: #fff;
    color: #555;
    font-weight: 300;
    font-size: 12px;
    text-transform: none; }
    .landing .landing-info div {
      display: block;
      margin: 5px 0; }
  .landing .landing-footer {
    padding: 20px 0;
    color: #fff;
    background-color: #fff; }
    .landing .landing-footer .landing-footer-logo {
      display: block;
      background: url("../images/plagiat-logo.png") no-repeat center;
      background-size: contain;
      width: 100%;
      min-height: 65px;
      margin: 20px 0;
      box-sizing: border-box; }
  .landing .tooltip .tooltiptext {
    margin-left: -160px;
    width: 360px;
    z-index: 103; }
  .landing jh-social {
    margin: 0; }
    .landing jh-social .login-with-btn {
      padding-top: 0; }
  .landing .landing-alert-div {
    line-height: 18px;
    margin-top: -2px; }
  .landing .landing-alert {
    color: #dd2c00;
    font-weight: 500;
    font-size: 12px; }

.currency-button {
  min-height: 30px;
  min-width: 55px;
  margin: 3px 4px; }

.online-order md-input-container .number-of-characters {
  min-width: 4rem; }

.online-order .opacity-9 {
  opacity: 0.9; }

.online-order md-card-actions {
  margin-top: auto; }

.online-order h3 {
  display: inline-block;
  padding: 5px; }

.online-order .base-price {
  color: gray;
  text-decoration: line-through; }

.online-order .discount {
  color: green; }

body.pl {
  background: #1a4585; }
  body.pl .payments .payment-metric .summary i.material-icons {
    color: #1a4585 !important; }
  body.pl .landing-header-logo-bg {
    background-color: #1a4585 !important; }
    body.pl .landing-header-logo-bg .landing-header-logo {
      background-image: url("../images/plagiat-logo.png"); }
  body.pl .landing-content .landing-title {
    color: #1a4585;
    font-weight: 400;
    font-size: 28px;
    text-transform: none; }
  body.pl .landing-form .landing-title {
    color: #1a4585;
    font-weight: 300;
    font-size: 14px;
    text-transform: none; }
    body.pl .landing-form .landing-title h3 {
      color: #1a4585;
      font-weight: 300;
      font-size: 16px;
      text-transform: none; }
  body.pl .landing-form md-content .btn-attach {
    color: #DD2C00;
    font-weight: 500;
    font-size: 18px;
    text-transform: ""; }
  body.pl .landing-footer {
    background-color: #1a4585 !important; }
    body.pl .landing-footer .landing-footer-logo {
      background: url("../images/plagiat-logo.png") no-repeat center; }

body.aka {
  background: #1a4585;
  color: #333;
  font-weight: "";
  font-size: 15px;
  text-transform: ""; }
  body.aka .unlogged .form .choose-lang {
    color: rgba(207, 216, 220, 0.9);
    font-weight: "";
    font-size: 11px;
    text-transform: ""; }
  body.aka .unlogged .form .login-header {
    color: #1a4585;
    font-weight: "";
    font-size: 20px;
    text-transform: ""; }
  body.aka .unlogged .form .login-logo {
    background-image: url("../images/aka-login-logo.png");
    width: 210px;
    height: 71px;
    margin: 10px auto; }
  body.aka md-checkbox.md-default-theme.md-checked .md-icon, body.aka md-checkbox.md-checked .md-icon {
    background-color: #1a4585; }
  body.aka md-table-pagination .label {
    color: #1a4585;
    font-weight: 500;
    font-size: 11px;
    text-transform: uppercase; }
  body.aka .md-button.md-default-theme.md-primary.md-fab,
  body.aka .md-button.md-primary.md-fab,
  body.aka .md-button.md-default-theme.md-primary.md-raised,
  body.aka .md-button.md-primary.md-raised {
    color: #fff;
    background-color: #1a4585; }
  body.aka .md-button.md-default-theme.md-primary.md-fab:not([disabled]).md-focused,
  body.aka .md-button.md-primary.md-fab:not([disabled]).md-focused,
  body.aka .md-button.md-default-theme.md-primary.md-fab:not([disabled]):hover,
  body.aka .md-button.md-primary.md-fab:not([disabled]):hover,
  body.aka .md-button.md-default-theme.md-primary.md-raised:not([disabled]).md-focused,
  body.aka .md-button.md-primary.md-raised:not([disabled]).md-focused,
  body.aka .md-button.md-default-theme.md-primary.md-raised:not([disabled]):hover,
  body.aka .md-button.md-primary.md-raised:not([disabled]):hover {
    color: #fff;
    background-color: #1a4585; }
  body.aka .main-color {
    color: #1a4585 !important; }
  body.aka .button-info {
    background-color: #1a4585 !important; }
  body.aka .payments .payment-metric .md-button.md-default-theme.md-primary.md-fab,
  body.aka .payments .payment-metric .md-button.md-primary.md-fab,
  body.aka .payments .payment-metric .md-button.md-default-theme.md-primary.md-raised,
  body.aka .payments .payment-metric .md-button.md-primary.md-raised,
  body.aka .payments .payment-metric md-toolbar.md-default-theme.md-hue-2:not(.md-menu-toolbar),
  body.aka .payments .payment-metric md-toolbar.md-hue-2:not(.md-menu-toolbar) {
    background-color: #1a4585 !important; }
  body.aka .payments .payment-metric .summary i.material-icons {
    color: #1a4585 !important; }
  body.aka .md-button.md-default-theme.md-accent[disabled], body.aka .md-button.md-accent[disabled], body.aka .md-button.md-default-theme.md-fab[disabled], body.aka .md-button.md-fab[disabled], body.aka .md-button.md-default-theme.md-raised[disabled], body.aka .md-button.md-raised[disabled], body.aka .md-button.md-default-theme.md-warn[disabled], body.aka .md-button.md-warn[disabled], body.aka .md-button.md-default-theme[disabled], body.aka .md-button[disabled] {
    color: #ccc;
    opacity: 0.75; }
  body.aka .active-step1 .steps-step-1 .step-counter,
  body.aka .active-step2 .steps-step-2 .step-counter,
  body.aka .active-step3 .steps-step-3 .step-counter {
    background-color: #1a4585 !important; }
  body.aka .info md-content.info-container .contact-form button.md-button.button-attach {
    color: #1a4585 !important; }
  body.aka .info md-content.info-container .contact-form button.md-button.button-send {
    background-color: #1a4585 !important; }
  body.aka .dragbox {
    background-color: rgba(0, 70, 170, 0.05); }
  body.aka .dragbox.has-advanced-upload {
    border-color: #333; }
  body.aka .dragbox-input label,
  body.aka .dragbox-icon-cloud {
    color: #0046aa;
    font-weight: 400;
    font-size: 20px;
    text-transform: none; }
  body.aka .md-button.md-default-theme.md-primary,
  body.aka .md-button.md-primary {
    color: #1a4585; }
  body.aka md-toolbar.md-default-theme:not(.md-menu-toolbar),
  body.aka md-toolbar:not(.md-menu-toolbar) {
    background-color: #1a4585; }
  body.aka md-toolbar.navbar {
    background-color: #1a4585;
    color: rgba(207, 216, 220, 0.9);
    font-weight: "";
    font-size: 11px;
    text-transform: "";
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12); }
    body.aka md-toolbar.navbar .navbar-container {
      max-height: 64px; }
    body.aka md-toolbar.navbar .navbar-logo {
      height: 100%;
      background: url("../images/aka-login-logo.png") no-repeat center center; }
  body.aka md-input-container md-select .md-select-value.md-select-placeholder,
  body.aka md-input-container label {
    color: #1a4585; }
  body.aka md-input-container.md-default-theme:not(.md-input-focused):not(.md-input-invalid) label.md-required::after, body.aka md-input-container:not(.md-input-focused):not(.md-input-invalid) label.md-required::after {
    color: #1a4585; }
  body.aka md-input-container.plagiat-input-color label {
    color: rgba(0, 0, 0, 0.38); }
  body.aka md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value label,
  body.aka md-input-container:not(.md-input-invalid).md-input-has-value label,
  body.aka md-input-container.md-default-theme:not(.md-input-invalid).md-input-focused label,
  body.aka md-input-container:not(.md-input-invalid).md-input-focused label {
    color: #1a4585; }
  body.aka md-input-container.md-input-focused .md-input {
    border-bottom-color: #1a4585 !important; }
  body.aka .md-sidenav-left md-content ul.sidenav-menu li a.md-button.active {
    border-left: 2px solid #1a4585; }
  body.aka md-select-menu.md-default-theme md-content md-option[selected]:focus, body.aka md-select-menu md-content md-option[selected]:focus {
    color: #1a4585; }
  body.aka md-select.md-default-theme:not([disabled]):focus .md-select-value, body.aka md-select:not([disabled]):focus .md-select-value {
    border-bottom-color: #1a4585; }
  body.aka md-radio-button.md-default-theme:not([disabled]).md-primary.md-checked .md-off, body.aka md-radio-button:not([disabled]).md-primary.md-checked .md-off, body.aka md-radio-button.md-default-theme:not([disabled]) .md-primary.md-checked .md-off, body.aka md-radio-button:not([disabled]) .md-primary.md-checked .md-off, body.aka md-radio-button.md-default-theme:not([disabled]).md-primary .md-checked .md-off, body.aka md-radio-button:not([disabled]).md-primary .md-checked .md-off, body.aka md-radio-button.md-default-theme:not([disabled]) .md-primary .md-checked .md-off, body.aka md-radio-button:not([disabled]) .md-primary .md-checked .md-off, body.aka md-radio-group.md-default-theme:not([disabled]).md-primary.md-checked .md-off, body.aka md-radio-group:not([disabled]).md-primary.md-checked .md-off, body.aka md-radio-group.md-default-theme:not([disabled]) .md-primary.md-checked .md-off, body.aka md-radio-group:not([disabled]) .md-primary.md-checked .md-off, body.aka md-radio-group.md-default-theme:not([disabled]).md-primary .md-checked .md-off, body.aka md-radio-group:not([disabled]).md-primary .md-checked .md-off, body.aka md-radio-group.md-default-theme:not([disabled]) .md-primary .md-checked .md-off, body.aka md-radio-group:not([disabled]) .md-primary .md-checked .md-off {
    border-color: #1a4585;
    color: #1a4585; }
  body.aka md-radio-button.md-default-theme:not([disabled]).md-primary .md-on, body.aka md-radio-button:not([disabled]).md-primary .md-on, body.aka md-radio-button.md-default-theme:not([disabled]) .md-primary .md-on, body.aka md-radio-button:not([disabled]) .md-primary .md-on, body.aka md-radio-group.md-default-theme:not([disabled]).md-primary .md-on, body.aka md-radio-group:not([disabled]).md-primary .md-on, body.aka md-radio-group.md-default-theme:not([disabled]) .md-primary .md-on, body.aka md-radio-group:not([disabled]) .md-primary .md-on {
    background-color: #1a4585; }
  body.aka md-switch.md-default-theme.md-checked.md-primary .md-thumb, body.aka md-switch.md-checked.md-primary .md-thumb {
    background-color: #1a4585; }
  body.aka md-switch.md-default-theme.md-checked.md-primary .md-bar, body.aka md-switch.md-checked.md-primary .md-bar {
    background-color: #1a4585;
    opacity: 0.5; }
  body.aka .md-default-theme .md-datepicker-open .md-datepicker-calendar-icon,
  body.aka .md-datepicker-open .md-datepicker-calendar-icon,
  body.aka md-input-container.md-input-focused .md-datepicker-calendar-icon {
    color: #1a4585; }
  body.aka .md-default-theme .md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator, body.aka .md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator {
    border-color: #1a4585; }
  body.aka .md-default-theme .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator, body.aka .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator, body.aka .md-default-theme .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator, body.aka .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator {
    background-color: #1a4585; }
  body.aka a, body.aka md-input-container.md-input-focused .md-placeholder,
  body.aka md-input-container.md-default-theme.md-input-focused md-select-value span,
  body.aka md-select-menu.md-default-theme md-content md-option[selected],
  body.aka md-select-menu md-content md-option[selected] {
    color: #1a4585; }
  body.aka md-progress-circular.md-default-theme path, body.aka md-progress-circular path {
    stroke: #1a4585; }
  body.aka .unlogged .form .register-header, body.aka .unlogged .form .md-subheader.md-default-theme.subheader-success, body.aka .unlogged .form .md-subheader.subheader-success {
    color: #1a4585;
    font-weight: 400;
    font-size: 20px;
    text-transform: none; }
  body.aka .button-danger {
    background-color: #1a4585 !important; }
  body.aka #loading-bar .bar {
    background-color: #1a4585 !important; }
  body.aka #loading-bar-spinner .spinner-icon {
    border-top-color: #1a4585 !important;
    border-left-color: #1a4585 !important; }
  body.aka .landing.landing-image .landing-header-logo-bg {
    background-color: #1a4585 !important; }
    body.aka .landing.landing-image .landing-header-logo-bg .landing-header-logo {
      background-image: url("../images/aka-login-logo.png"); }
  body.aka .landing.landing-image .landing-content .landing-title {
    color: #1a4585;
    font-weight: 400;
    font-size: 28px;
    text-transform: none; }
  body.aka .landing.landing-image .landing-form .landing-title {
    color: #1a4585;
    font-weight: 300;
    font-size: 14px;
    text-transform: none; }
    body.aka .landing.landing-image .landing-form .landing-title h3 {
      color: #1a4585;
      font-weight: 300;
      font-size: 16px;
      text-transform: none; }
  body.aka .landing.landing-image .landing-form md-content .btn-attach {
    color: #DD2C00;
    font-weight: 500;
    font-size: 18px;
    text-transform: ""; }
  body.aka .landing .landing-footer {
    color: #1a4585;
    background-color: #1a4585 !important; }
    body.aka .landing .landing-footer .landing-footer-logo {
      width: 210px;
      height: 71px;
      margin: 10px auto;
      background: url("../images/aka-login-logo.png") no-repeat center;
      background-size: cover; }
  body.aka md-chips.md-default-theme.md-chips.md-focused, body.aka md-chips .md-chips.md-focused {
    box-shadow: 0 2px #1a4585 !important; }
  body.aka #tableDocuments md-menu-bar .md-button.md-icon-button {
    width: 18px;
    padding: 0; }
  body.aka #tableDocuments.md-table th.md-column.documents-status, body.aka #tableDocuments.md-table td.md-cell.documents-status {
    text-align: center; }
  body.aka #tableDocuments.md-table th.md-column.documents-wp {
    text-align: left; }
  body.aka md-input-container.md-input-focused:not(.md-input-has-value) md-select.md-default-theme .md-select-value, body.aka md-input-container.md-input-focused:not(.md-input-has-value) md-select .md-select-value, body.aka md-input-container.md-input-focused:not(.md-input-has-value) md-select.md-default-theme .md-select-value.md-select-placeholder, body.aka md-input-container.md-input-focused:not(.md-input-has-value) md-select .md-select-value.md-select-placeholder {
    color: #1a4585; }
  body.aka .out-circle {
    position: relative;
    left: 40%;
    margin-top: 2px; }
  body.aka .document-client-details .out-circle {
    margin-top: 19px; }
  body.aka md-progress-linear.md-default-theme .md-container, body.aka md-progress-linear .md-container {
    background-color: rgba(207, 216, 220, 0.9); }
  body.aka md-autocomplete md-progress-linear .md-bar {
    background-color: #1a4585; }
  body.aka md-chip.md-focused {
    background-color: #1a4585; }
  body.aka .user-query-counter-block md-icon, body.aka .user-query-counter-block .md-input-focused md-icon {
    color: #1a4585; }
  body.aka md-bottom-sheet {
    background-color: #1a4585;
    color: #000; }
    body.aka md-bottom-sheet i {
      color: #fff; }

body.ro {
  background: #d8d8d8;
  color: #333;
  font-weight: "";
  font-size: 15px;
  text-transform: ""; }
  body.ro .unlogged .form .choose-lang {
    color: #717171;
    font-weight: "";
    font-size: 11px;
    text-transform: ""; }
  body.ro .unlogged .form .login-header {
    color: #C52D27;
    font-weight: "";
    font-size: 20px;
    text-transform: ""; }
  body.ro .unlogged .form .login-logo {
    background-image: url("../images/ro-login-logo.png");
    width: 210px;
    height: 71px;
    margin: 10px auto 10px; }
  body.ro md-checkbox.md-default-theme.md-checked .md-icon, body.ro md-checkbox.md-checked .md-icon {
    background-color: #A31D21; }
  body.ro md-table-pagination .label {
    color: #A31D21;
    font-weight: 500;
    font-size: 11px;
    text-transform: uppercase; }
  body.ro .md-button.md-default-theme.md-primary.md-fab,
  body.ro .md-button.md-primary.md-fab,
  body.ro .md-button.md-default-theme.md-primary.md-raised,
  body.ro .md-button.md-primary.md-raised {
    color: #fff;
    background-color: #A31D21; }
  body.ro .md-button.md-default-theme.md-primary.md-fab:not([disabled]).md-focused,
  body.ro .md-button.md-primary.md-fab:not([disabled]).md-focused,
  body.ro .md-button.md-default-theme.md-primary.md-fab:not([disabled]):hover,
  body.ro .md-button.md-primary.md-fab:not([disabled]):hover,
  body.ro .md-button.md-default-theme.md-primary.md-raised:not([disabled]).md-focused,
  body.ro .md-button.md-primary.md-raised:not([disabled]).md-focused,
  body.ro .md-button.md-default-theme.md-primary.md-raised:not([disabled]):hover,
  body.ro .md-button.md-primary.md-raised:not([disabled]):hover {
    color: #fff;
    background-color: #A31D21; }
  body.ro .main-color {
    color: #C52D27 !important; }
  body.ro .button-info {
    background-color: #A31D21 !important; }
  body.ro .payments .payment-metric .md-button.md-default-theme.md-primary.md-fab,
  body.ro .payments .payment-metric .md-button.md-primary.md-fab,
  body.ro .payments .payment-metric .md-button.md-default-theme.md-primary.md-raised,
  body.ro .payments .payment-metric .md-button.md-primary.md-raised,
  body.ro .payments .payment-metric md-toolbar.md-default-theme.md-hue-2:not(.md-menu-toolbar),
  body.ro .payments .payment-metric md-toolbar.md-hue-2:not(.md-menu-toolbar) {
    background-color: #A31D21 !important; }
  body.ro .payments .payment-metric .summary i.material-icons {
    color: #A31D21 !important; }
  body.ro .md-button.md-default-theme.md-accent[disabled], body.ro .md-button.md-accent[disabled], body.ro .md-button.md-default-theme.md-fab[disabled], body.ro .md-button.md-fab[disabled], body.ro .md-button.md-default-theme.md-raised[disabled], body.ro .md-button.md-raised[disabled], body.ro .md-button.md-default-theme.md-warn[disabled], body.ro .md-button.md-warn[disabled], body.ro .md-button.md-default-theme[disabled], body.ro .md-button[disabled] {
    color: #ccc;
    opacity: 0.75; }
  body.ro .active-step1 .steps-step-1 .step-counter,
  body.ro .active-step2 .steps-step-2 .step-counter,
  body.ro .active-step3 .steps-step-3 .step-counter {
    background-color: #A31D21 !important; }
  body.ro .info md-content.info-container .contact-form button.md-button.button-attach {
    color: #A31D21 !important; }
  body.ro .info md-content.info-container .contact-form button.md-button.button-send {
    background-color: #A31D21 !important; }
  body.ro .dragbox {
    background-color: #f0f1f2; }
  body.ro .dragbox.has-advanced-upload {
    border-color: #717171; }
  body.ro .dragbox-input label,
  body.ro .dragbox-icon-cloud {
    color: #717171; }
  body.ro .md-button.md-default-theme.md-primary,
  body.ro .md-button.md-primary {
    color: #A31D21; }
  body.ro md-toolbar.md-default-theme:not(.md-menu-toolbar),
  body.ro md-toolbar:not(.md-menu-toolbar) {
    background-color: #A31D21; }
  body.ro md-toolbar.navbar {
    background-color: #fff;
    color: #717171;
    font-weight: "";
    font-size: 11px;
    text-transform: "";
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    body.ro md-toolbar.navbar .navbar-container {
      max-height: 64px; }
    body.ro md-toolbar.navbar .navbar-logo {
      height: 100%;
      background: url("../images/ro-login-logo.png") no-repeat;
      margin-left: 10px; }
  body.ro md-input-container md-select .md-select-value.md-select-placeholder,
  body.ro md-input-container label {
    color: #C52D27; }
  body.ro md-input-container.md-default-theme:not(.md-input-focused):not(.md-input-invalid) label.md-required::after, body.ro md-input-container:not(.md-input-focused):not(.md-input-invalid) label.md-required::after {
    color: #C52D27; }
  body.ro md-input-container.plagiat-input-color label {
    color: rgba(0, 0, 0, 0.38); }
  body.ro md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value label,
  body.ro md-input-container:not(.md-input-invalid).md-input-has-value label,
  body.ro md-input-container.md-default-theme:not(.md-input-invalid).md-input-focused label,
  body.ro md-input-container:not(.md-input-invalid).md-input-focused label {
    color: #C52D27; }
  body.ro md-input-container.md-input-focused .md-input {
    border-bottom-color: #C52D27 !important; }
  body.ro .md-sidenav-left md-content ul.sidenav-menu li a.md-button.active {
    border-left: 2px solid #A31D21; }
  body.ro md-select-menu.md-default-theme md-content md-option[selected]:focus, body.ro md-select-menu md-content md-option[selected]:focus {
    color: #A31D21; }
  body.ro md-select.md-default-theme:not([disabled]):focus .md-select-value, body.ro md-select:not([disabled]):focus .md-select-value {
    border-bottom-color: #A31D21; }
  body.ro md-radio-button.md-default-theme:not([disabled]).md-primary.md-checked .md-off, body.ro md-radio-button:not([disabled]).md-primary.md-checked .md-off, body.ro md-radio-button.md-default-theme:not([disabled]) .md-primary.md-checked .md-off, body.ro md-radio-button:not([disabled]) .md-primary.md-checked .md-off, body.ro md-radio-button.md-default-theme:not([disabled]).md-primary .md-checked .md-off, body.ro md-radio-button:not([disabled]).md-primary .md-checked .md-off, body.ro md-radio-button.md-default-theme:not([disabled]) .md-primary .md-checked .md-off, body.ro md-radio-button:not([disabled]) .md-primary .md-checked .md-off, body.ro md-radio-group.md-default-theme:not([disabled]).md-primary.md-checked .md-off, body.ro md-radio-group:not([disabled]).md-primary.md-checked .md-off, body.ro md-radio-group.md-default-theme:not([disabled]) .md-primary.md-checked .md-off, body.ro md-radio-group:not([disabled]) .md-primary.md-checked .md-off, body.ro md-radio-group.md-default-theme:not([disabled]).md-primary .md-checked .md-off, body.ro md-radio-group:not([disabled]).md-primary .md-checked .md-off, body.ro md-radio-group.md-default-theme:not([disabled]) .md-primary .md-checked .md-off, body.ro md-radio-group:not([disabled]) .md-primary .md-checked .md-off {
    border-color: #A31D21;
    color: #C52D27; }
  body.ro md-radio-button.md-default-theme:not([disabled]).md-primary .md-on, body.ro md-radio-button:not([disabled]).md-primary .md-on, body.ro md-radio-button.md-default-theme:not([disabled]) .md-primary .md-on, body.ro md-radio-button:not([disabled]) .md-primary .md-on, body.ro md-radio-group.md-default-theme:not([disabled]).md-primary .md-on, body.ro md-radio-group:not([disabled]).md-primary .md-on, body.ro md-radio-group.md-default-theme:not([disabled]) .md-primary .md-on, body.ro md-radio-group:not([disabled]) .md-primary .md-on {
    background-color: #A31D21; }
  body.ro md-switch.md-default-theme.md-checked.md-primary .md-thumb, body.ro md-switch.md-checked.md-primary .md-thumb {
    background-color: #A31D21; }
  body.ro md-switch.md-default-theme.md-checked.md-primary .md-bar, body.ro md-switch.md-checked.md-primary .md-bar {
    background-color: #A31D21;
    opacity: 0.5; }
  body.ro .md-default-theme .md-datepicker-open .md-datepicker-calendar-icon,
  body.ro .md-datepicker-open .md-datepicker-calendar-icon,
  body.ro md-input-container.md-input-focused .md-datepicker-calendar-icon {
    color: #A31D21; }
  body.ro .md-default-theme .md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator, body.ro .md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator {
    border-color: #A31D21; }
  body.ro .md-default-theme .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator, body.ro .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator, body.ro .md-default-theme .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator, body.ro .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator {
    background-color: #A31D21; }
  body.ro a, body.ro md-input-container.md-input-focused .md-placeholder,
  body.ro md-input-container.md-default-theme.md-input-focused md-select-value span,
  body.ro md-select-menu.md-default-theme md-content md-option[selected],
  body.ro md-select-menu md-content md-option[selected] {
    color: #A31D21; }
  body.ro md-progress-circular.md-default-theme path, body.ro md-progress-circular path {
    stroke: #A31D21; }
  body.ro .unlogged .form .register-header, body.ro .unlogged .form .md-subheader.md-default-theme.subheader-success, body.ro .unlogged .form .md-subheader.subheader-success {
    color: #C52D27;
    font-weight: 400;
    font-size: 20px;
    text-transform: none; }
  body.ro .button-danger {
    background-color: #A31D21 !important; }
  body.ro #loading-bar .bar {
    background-color: #A31D21 !important; }
  body.ro #loading-bar-spinner .spinner-icon {
    border-top-color: #A31D21 !important;
    border-left-color: #A31D21 !important; }
  body.ro .landing.landing-image .landing-header-logo-bg {
    background-color: #d8d8d8 !important; }
    body.ro .landing.landing-image .landing-header-logo-bg .landing-header-logo {
      background-image: url("../images/ro-login-logo.png"); }
  body.ro .landing.landing-image .landing-content .landing-title {
    color: #A31D21;
    font-weight: 400;
    font-size: 28px;
    text-transform: none; }
  body.ro .landing.landing-image .landing-form .landing-title {
    color: #A31D21;
    font-weight: 300;
    font-size: 14px;
    text-transform: none; }
    body.ro .landing.landing-image .landing-form .landing-title h3 {
      color: #A31D21;
      font-weight: 300;
      font-size: 16px;
      text-transform: none; }
  body.ro .landing.landing-image .landing-form md-content .btn-attach {
    color: #DD2C00;
    font-weight: 500;
    font-size: 18px;
    text-transform: ""; }
  body.ro .landing .landing-footer {
    color: #C52D27;
    background-color: #d8d8d8 !important; }
    body.ro .landing .landing-footer .landing-footer-logo {
      width: 210px;
      height: 71px;
      margin: 10px auto 10px;
      background: url("../images/ro-login-logo.png") no-repeat center;
      background-size: cover; }
  body.ro md-chips.md-default-theme.md-chips.md-focused, body.ro md-chips .md-chips.md-focused {
    box-shadow: 0 2px #C52D27 !important; }
  body.ro #tableDocuments md-menu-bar .md-button.md-icon-button {
    width: 18px;
    padding: 0; }
  body.ro #tableDocuments.md-table th.md-column.documents-status, body.ro #tableDocuments.md-table td.md-cell.documents-status {
    text-align: center; }
  body.ro #tableDocuments.md-table th.md-column.documents-wp {
    text-align: left; }
  body.ro md-input-container.md-input-focused:not(.md-input-has-value) md-select.md-default-theme .md-select-value, body.ro md-input-container.md-input-focused:not(.md-input-has-value) md-select .md-select-value, body.ro md-input-container.md-input-focused:not(.md-input-has-value) md-select.md-default-theme .md-select-value.md-select-placeholder, body.ro md-input-container.md-input-focused:not(.md-input-has-value) md-select .md-select-value.md-select-placeholder {
    color: #C52D27; }
  body.ro .out-circle {
    position: relative;
    left: 40%;
    margin-top: 2px; }
  body.ro .document-client-details .out-circle {
    margin-top: 19px; }
  body.ro md-progress-linear.md-default-theme .md-container, body.ro md-progress-linear .md-container {
    background-color: #d69a9c; }
  body.ro md-autocomplete md-progress-linear .md-bar {
    background-color: #A31D21; }
  body.ro md-chip.md-focused {
    background-color: #C52D27; }
  body.ro .user-query-counter-block md-icon, body.ro .user-query-counter-block .md-input-focused md-icon {
    color: #A31D21; }
  body.ro .out-circle {
    position: relative;
    left: 40%;
    margin-top: 2px; }
  body.ro .document-client-details .out-circle {
    margin-top: 19px; }
  body.ro md-bottom-sheet {
    background-color: #d8d8d8;
    color: #000; }
    body.ro md-bottom-sheet i {
      color: #A31D21; }

body.int {
  background: #D8D8D8;
  color: #333;
  font-weight: "";
  font-size: 15px;
  text-transform: ""; }
  body.int .unlogged .form .choose-lang {
    margin-top: 8px;
    color: #717171;
    font-weight: "";
    font-size: 11px;
    text-transform: ""; }
  body.int .unlogged .form .login-header {
    color: #8C0E1D;
    font-weight: "";
    font-size: 20px;
    text-transform: ""; }
  body.int .unlogged .form .login-logo {
    background-image: url("../images/en-login-logo-2.png");
    width: 210px;
    height: 71px;
    margin: 10px auto 10px; }
  body.int md-checkbox.md-default-theme.md-checked .md-icon, body.int md-checkbox.md-checked .md-icon {
    background-color: #00C853; }
  body.int md-table-pagination .label {
    color: #d8d8d8;
    font-weight: 500;
    font-size: 11px;
    text-transform: uppercase; }
  body.int .md-button.md-default-theme.md-primary.md-fab,
  body.int .md-button.md-primary.md-fab,
  body.int .md-button.md-default-theme.md-primary.md-raised,
  body.int .md-button.md-primary.md-raised {
    color: #fff;
    background-color: #8C0E1D; }
  body.int .md-button.md-default-theme.md-primary.md-fab:not([disabled]).md-focused,
  body.int .md-button.md-primary.md-fab:not([disabled]).md-focused,
  body.int .md-button.md-default-theme.md-primary.md-fab:not([disabled]):hover,
  body.int .md-button.md-primary.md-fab:not([disabled]):hover,
  body.int .md-button.md-default-theme.md-primary.md-raised:not([disabled]).md-focused,
  body.int .md-button.md-primary.md-raised:not([disabled]).md-focused,
  body.int .md-button.md-default-theme.md-primary.md-raised:not([disabled]):hover,
  body.int .md-button.md-primary.md-raised:not([disabled]):hover {
    color: #fff;
    background-color: #8C0E1D; }
  body.int .main-color {
    color: #8C0E1D !important; }
  body.int .button-info {
    background-color: #8C0E1D !important; }
  body.int .payments .payment-metric .md-button.md-default-theme.md-primary.md-fab,
  body.int .payments .payment-metric .md-button.md-primary.md-fab,
  body.int .payments .payment-metric .md-button.md-default-theme.md-primary.md-raised,
  body.int .payments .payment-metric .md-button.md-primary.md-raised,
  body.int .payments .payment-metric md-toolbar.md-default-theme.md-hue-2:not(.md-menu-toolbar),
  body.int .payments .payment-metric md-toolbar.md-hue-2:not(.md-menu-toolbar) {
    background-color: #8C0E1D !important; }
  body.int .payments .payment-metric .summary i.material-icons {
    color: #8C0E1D !important; }
  body.int .md-button.md-default-theme.md-accent[disabled], body.int .md-button.md-accent[disabled], body.int .md-button.md-default-theme.md-fab[disabled], body.int .md-button.md-fab[disabled], body.int .md-button.md-default-theme.md-raised[disabled], body.int .md-button.md-raised[disabled], body.int .md-button.md-default-theme.md-warn[disabled], body.int .md-button.md-warn[disabled], body.int .md-button.md-default-theme[disabled], body.int .md-button[disabled] {
    color: #ccc;
    opacity: 0.75; }
  body.int .active-step1 .steps-step-1 .step-counter,
  body.int .active-step2 .steps-step-2 .step-counter,
  body.int .active-step3 .steps-step-3 .step-counter {
    background-color: #8C0E1D !important; }
  body.int .info md-content.info-container .contact-form button.md-button.button-attach {
    color: #8C0E1D !important; }
  body.int .info md-content.info-container .contact-form button.md-button.button-send {
    background-color: #8C0E1D !important; }
  body.int .dragbox {
    background-color: #f0f1f2; }
  body.int .dragbox.has-advanced-upload {
    border-color: #8c0e1d; }
  body.int .dragbox-input label,
  body.int .dragbox-icon-cloud {
    color: #8c0e1d; }
  body.int .md-button.md-default-theme.md-primary,
  body.int .md-button.md-primary {
    color: #A31D21; }
  body.int md-toolbar.md-default-theme:not(.md-menu-toolbar),
  body.int md-toolbar:not(.md-menu-toolbar) {
    background-color: #8C0E1D; }
  body.int md-toolbar.navbar {
    background-color: #fff;
    color: #8c0e1d;
    font-weight: "";
    font-size: 11px;
    text-transform: "";
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    body.int md-toolbar.navbar .navbar-container {
      max-height: 64px; }
    body.int md-toolbar.navbar .navbar-logo {
      height: 100%;
      background: url("../images/en-login-logo-1.png") no-repeat center center;
      margin: 0 0 0.3rem 1rem; }
  body.int md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value label,
  body.int md-input-container:not(.md-input-invalid).md-input-has-value label,
  body.int md-input-container.md-default-theme:not(.md-input-invalid).md-input-focused label,
  body.int md-input-container:not(.md-input-invalid).md-input-focused label {
    color: #8C0E1D; }
  body.int md-input-container.md-default-theme .md-input, body.int md-input-container.md-input-focused .md-input {
    border-bottom-color: #8C0E1D !important; }
  body.int .md-sidenav-left md-content ul.sidenav-menu li a.md-button.active {
    border-left: 2px solid #8C0E1D; }
  body.int md-select-menu.md-default-theme md-content md-option[selected]:focus, body.int md-select-menu md-content md-option[selected]:focus {
    color: #8C0E1D; }
  body.int md-select.md-default-theme:not([disabled]):focus .md-select-value, body.int md-select:not([disabled]):focus .md-select-value {
    border-bottom-color: #8C0E1D; }
  body.int md-radio-button.md-default-theme:not([disabled]).md-primary.md-checked .md-off, body.int md-radio-button:not([disabled]).md-primary.md-checked .md-off, body.int md-radio-button.md-default-theme:not([disabled]) .md-primary.md-checked .md-off, body.int md-radio-button:not([disabled]) .md-primary.md-checked .md-off, body.int md-radio-button.md-default-theme:not([disabled]).md-primary .md-checked .md-off, body.int md-radio-button:not([disabled]).md-primary .md-checked .md-off, body.int md-radio-button.md-default-theme:not([disabled]) .md-primary .md-checked .md-off, body.int md-radio-button:not([disabled]) .md-primary .md-checked .md-off, body.int md-radio-group.md-default-theme:not([disabled]).md-primary.md-checked .md-off, body.int md-radio-group:not([disabled]).md-primary.md-checked .md-off, body.int md-radio-group.md-default-theme:not([disabled]) .md-primary.md-checked .md-off, body.int md-radio-group:not([disabled]) .md-primary.md-checked .md-off, body.int md-radio-group.md-default-theme:not([disabled]).md-primary .md-checked .md-off, body.int md-radio-group:not([disabled]).md-primary .md-checked .md-off, body.int md-radio-group.md-default-theme:not([disabled]) .md-primary .md-checked .md-off, body.int md-radio-group:not([disabled]) .md-primary .md-checked .md-off {
    border-color: #8C0E1D;
    color: #8C0E1D; }
  body.int md-radio-button.md-default-theme:not([disabled]).md-primary .md-on, body.int md-radio-button:not([disabled]).md-primary .md-on, body.int md-radio-button.md-default-theme:not([disabled]) .md-primary .md-on, body.int md-radio-button:not([disabled]) .md-primary .md-on, body.int md-radio-group.md-default-theme:not([disabled]).md-primary .md-on, body.int md-radio-group:not([disabled]).md-primary .md-on, body.int md-radio-group.md-default-theme:not([disabled]) .md-primary .md-on, body.int md-radio-group:not([disabled]) .md-primary .md-on {
    background-color: #8C0E1D; }
  body.int md-switch.md-default-theme.md-checked.md-primary .md-thumb, body.int md-switch.md-checked.md-primary .md-thumb {
    background-color: #8C0E1D; }
  body.int md-switch.md-default-theme.md-checked.md-primary .md-bar, body.int md-switch.md-checked.md-primary .md-bar {
    background-color: #8C0E1D;
    opacity: 0.5; }
  body.int .md-default-theme .md-datepicker-open .md-datepicker-calendar-icon,
  body.int .md-datepicker-open .md-datepicker-calendar-icon,
  body.int md-input-container.md-input-focused .md-datepicker-calendar-icon {
    color: #8C0E1D; }
  body.int .md-default-theme .md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator, body.int .md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator {
    border-color: #8C0E1D; }
  body.int .md-default-theme .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator, body.int .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator, body.int .md-default-theme .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator, body.int .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator {
    background-color: #8C0E1D; }
  body.int a, body.int md-input-container.md-input-focused .md-placeholder,
  body.int md-input-container.md-default-theme.md-input-focused md-select-value span,
  body.int md-select-menu.md-default-theme md-content md-option[selected],
  body.int md-select-menu md-content md-option[selected] {
    color: #8C0E1D; }
  body.int md-progress-circular.md-default-theme path, body.int md-progress-circular path {
    stroke: #8C0E1D; }
  body.int .unlogged .form .register-header, body.int .unlogged .form .md-subheader.md-default-theme.subheader-success, body.int .unlogged .form .md-subheader.subheader-success {
    color: #8C0E1D;
    font-weight: 400;
    font-size: 20px;
    text-transform: none; }
  body.int .button-danger {
    background-color: #8C0E1D !important; }
  body.int #loading-bar .bar {
    background-color: #8C0E1D !important; }
  body.int #loading-bar-spinner .spinner-icon {
    border-top-color: #8C0E1D !important;
    border-left-color: #8C0E1D !important; }
  body.int .landing.landing-image .landing-header-logo-bg {
    background-color: #D8D8D8 !important; }
    body.int .landing.landing-image .landing-header-logo-bg .landing-header-logo {
      background-image: url("../images/en-login-logo-2.png"); }
  body.int .landing.landing-image .landing-content .landing-title {
    color: #8C0E1D;
    font-weight: 400;
    font-size: 28px;
    text-transform: none; }
  body.int .landing.landing-image .landing-form .landing-title {
    color: #8C0E1D;
    font-weight: 300;
    font-size: 14px;
    text-transform: none; }
    body.int .landing.landing-image .landing-form .landing-title h3 {
      color: #8C0E1D;
      font-weight: 300;
      font-size: 16px;
      text-transform: none; }
  body.int .landing.landing-image .landing-form md-content .btn-attach {
    color: #DD2C00;
    font-weight: 500;
    font-size: 18px;
    text-transform: ""; }
  body.int .landing .landing-footer {
    color: #8C0E1D;
    background-color: #D8D8D8 !important; }
    body.int .landing .landing-footer .landing-footer-logo {
      background: url("../images/en-login-logo-2.png") no-repeat center; }
  body.int md-bottom-sheet {
    background-color: #8C0E1D; }
    body.int md-bottom-sheet md-checkbox.md-checked .md-icon {
      background-color: #000; }
    body.int md-bottom-sheet i {
      color: #fff; }

@media screen and (min-width: 0) {
  md-dialog .tooltip .tooltiptext {
    bottom: 105%;
    margin-left: -480px;
    visibility: visible;
    width: 1280px; } }

@media screen and (max-width: 1920px) {
  md-dialog .tooltip .tooltiptext {
    bottom: 105%;
    margin-left: -180px;
    visibility: hidden;
    width: 1050px;
    font-size: 15px; }
  md-dialog .accept-terms-content {
    max-width: 1050px; } }

@media screen and (max-width: 1600px) {
  md-dialog .tooltip .tooltiptext {
    bottom: 105%;
    visibility: hidden;
    width: 1050px; } }

@media screen and (max-width: 1280px) {
  md-dialog .tooltip .tooltiptext {
    bottom: 105%;
    visibility: hidden;
    max-width: 580px; } }

@media screen and (max-width: 992px) {
  md-dialog .tooltip .tooltiptext {
    bottom: 105%;
    margin-left: -120px;
    visibility: hidden;
    width: 600px; } }

@media screen and (max-width: 600px) {
  md-bottom-sheet span {
    padding: 3px 3px 3px 0 !important; }
  .tooltip .tooltiptext {
    margin-left: -140px;
    width: 300px;
    z-index: 103; }
  md-dialog .tooltip .tooltiptext {
    bottom: 105%;
    margin-left: -110px;
    visibility: hidden;
    width: 280px; }
  .md-sidenav-left {
    min-width: 90px;
    width: 90px; }
  .unlogged md-content .form {
    min-width: 320px; }
  .landing.landing-image {
    background-image: none; } }

@media screen and (max-width: 1025px) {
  md-content {
    max-width: 1025px; } }

@media screen and (max-width: 769px) {
  md-content {
    max-width: 769px; }
  md-dialog {
    max-width: 90%;
    max-height: 90%; }
  md-dialog .tooltip .tooltiptext {
    font-size: 12px; } }

@media screen and (max-width: 426px) {
  html, body {
    height: 100%; }
  md-content {
    max-width: 426px; }
    md-content.users {
      padding: 0; }
      md-content.users .md-nav-item:first-of-type {
        margin: 0; }
      md-content.users .md-button._md-nav-button {
        padding-left: 4px;
        padding-right: 4px;
        width: 145px;
        overflow: hidden; }
      md-content.users .tabs-menu-li-elipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
  md-select {
    padding-bottom: 20px; }
  .m-top {
    margin-top: 30px; } }

@media screen and (max-width: 376px) {
  md-content {
    max-width: 376px; }
    md-content.users .md-button._md-nav-button {
      width: 120px; } }

@media screen and (max-width: 321px) {
  .unlogged md-content .form {
    min-width: 321px; }
  md-content {
    max-width: 321px; }
    md-content.users .md-button._md-nav-button {
      width: 100px; }
  .md-datepicker-input {
    max-width: 220px; }
  .landing {
    max-width: 321px; }
    .landing.landing-image .landing-content .landing-red-mark {
      width: 180px; } }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-reset {
  color: inherit !important; }

/* jhipster-needle-scss-add-main JHipster will add new css style */
.hidden {
  /* for has-any-authority */
  display: none !important; }

div.table-container md-content md-card {
  width: 0; }

md-content.md-default-theme, md-content {
  background: inherit; }

.flex-wrap {
  flex-wrap: wrap; }

span.align-vertical {
  display: flex;
  align-items: center;
  justify-content: center; }

.vertical-divider {
  border-top: 0;
  border-right: 1px solid;
  border-color: gray;
  margin-left: 3rem;
  margin-right: 3rem; }

.md-button[disabled], .md-button[disabled]:hover {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.38) !important; }

.no-padding-margin {
  padding: 0 !important;
  margin: 0 !important; }

.dragbox-input {
  height: 100%;
  display: inline-block;
  width: 100%; }

md-autocomplete {
  min-width: 0 !important; }
  md-autocomplete md-input-container input {
    margin-top: 14px !important;
    padding-top: 5px !important;
    max-height: 20.5px !important; }

md-input-container input {
  padding-top: 14px !important; }

md-input-container textarea {
  margin-top: 14px !important; }

md-input-container label:not(.md-placeholder) {
  margin-bottom: -14px !important;
  padding-top: 0 !important; }

md-input-container md-select md-select-value span {
  padding-top: 9px !important;
  padding-bottom: 0 !important; }

md-input-container md-datepicker .md-datepicker-button {
  margin-top: 0 !important; }

.fix-md-input-container input {
  height: 1.5rem;
  padding-top: 0 !important; }

.fix-md-input-container label:not(.md-placeholder) {
  margin-bottom: 0.5rem !important; }

div.box-limit span {
  padding-top: 2px !important; }

div.go-to input {
  padding-top: 2px !important; }

.accept-data-processing-tooltip {
  width: 320px !important;
  height: auto !important;
  background-color: #555;
  white-space: pre-line;
  font-size: 12px !important; }

.auto-translations-tooltip {
  width: 320px !important;
  height: auto !important;
  background-color: #555;
  white-space: pre-line;
  font-size: 12px !important; }

@media screen and (max-width: 800px) {
  .accept-data-processing-tooltip {
    width: auto !important; } }

.youtube-holder-1 {
  width: 100%; }

.youtube-holder-2 {
  padding-top: 56.25%;
  position: relative; }

.youtube-holder-2 iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 99%;
  height: 99%; }

.three-state-radio {
  /* height */
  /* width */
  /* toggle dimensions */
  /* font size */
  /* toggle's border thickness */
  /* toggle box shadow */
  /* labels animation time */
  /* label text color */
  width: 3.75rem;
  min-width: 3.75rem;
  height: 1.25rem;
  position: relative;
  margin: 0.1rem auto;
  border-radius: 0.625rem;
  background: #9e9e9e; }
  .three-state-radio.yes-chosen {
    background: green !important; }
  .three-state-radio .three-state-toggle {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: 1.125rem;
    top: -0.125rem;
    border-radius: 50%;
    box-sizing: border-box;
    background: white;
    box-shadow: 0 1px 3px 0 #9e9e9e, 0 1px 1px 0 #9e9e9e, 0 2px 1px -1px #9e9e9e;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .three-state-radio label {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 1;
    display: inline-block;
    text-align: center;
    line-height: 1.25rem;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    color: white;
    -webkit-animation: 6s rot-label ease-in-out infinite;
    -moz-animation: 6s rot-label ease-in-out infinite;
    -o-animation: 6s rot-label ease-in-out infinite;
    animation: 6s rot-label ease-in-out infinite; }
    .three-state-radio label.selected {
      color: #9e9e9e; }
  .three-state-radio input {
    position: absolute;
    left: 0;
    margin: 0;
    padding: 0;
    opacity: 0; }
  .three-state-radio .yes-lbl.selected {
    color: green; }
  .three-state-radio .no-lbl.selected ~ .three-state-toggle {
    left: -0.125rem; }
  .three-state-radio .yes-lbl.selected ~ .three-state-toggle {
    left: 2.375rem; }
  .three-state-radio .maybe-radio, .three-state-radio .maybe-lbl {
    left: 33.33333%; }
  .three-state-radio .yes-radio, .three-state-radio .yes-lbl {
    left: 66.66667%; }
  .three-state-radio .maybe-lbl {
    -webkit-animation-delay: 2s;
    -o-animation-delay: 2s;
    -moz-animation-delay: 2s;
    animation-delay: 2s; }
  .three-state-radio .yes-lbl {
    -webkit-animation-delay: 4s;
    -o-animation-delay: 4s;
    -moz-animation-delay: 4s;
    animation-delay: 4s; }

three-state-radio.ng-invalid.ng-touched label.custom-label {
  color: #dd2c00; }

three-state-radio.ng-invalid.ng-touched div.three-state-radio {
  background: #dd2c00; }

three-state-radio .immutable {
  opacity: 0.6; }

.three-state-radio-wrapper {
  align-items: center; }

.cursor-pointer {
  cursor: pointer; }

.header-description {
  font-size: 14px !important;
  font-weight: normal;
  margin: 8px;
  display: block; }

.peer-review-question-div .buttons {
  min-width: fit-content; }
